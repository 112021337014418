Siepomaga.Popup = class Popup {
  constructor() {
    $("[data-content], [data-html]").popup({
      delay: {
        show: 0,
        hide: 0,
      },
      hideOnScroll: true,
    });
  }
};
