Siepomaga.common_credit_cards = {
  new() {
    Siepomaga.onlyMobile({
      create() {
        $(".iframe-container iframe").attr("height", "530px");
      },
      destroy() {
        $(".iframe-container iframe").attr("height", "430px");
      },
    });
  },

  create() {
    Siepomaga.common_credit_cards.new();
  },

  iframe() {
    new Siepomaga.CreditCard();
  },
};
