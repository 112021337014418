/* eslint-disable max-classes-per-file */

Siepomaga.FlashMessages = class FlashMessages {
  constructor() {
    $("#flash_messages .message").each((i, el) => {
      const data = $(el).data();
      const { type } = data;

      Lobibox.notify(
        type,
        data,
      );

      $(el).remove();
    });
  }
};

Siepomaga.FlashMessages.DefaultConfig = class DefaultConfig {
  constructor() {
    Lobibox.notify.OPTIONS = $.extend({}, Lobibox.notify.OPTIONS, {
      default: {
        class: "lobibox-notify-default",
      },
      success: {
        class: "flash flash-success",
      },
      error: {
        class: "flash flash-error",
      },
      warning: {
        class: "flash flash-warning",
      },
      info: {
        class: "flash flash-info",
      },
      icons: {
        semantic: {
          success: "check circle icon",
          error: "exclamation circle icon",
          warning: "exclamation triangle icon",
          info: "info circle icon",
        },
      },
    });

    Lobibox.notify.DEFAULTS = $.extend({}, Lobibox.notify.DEFAULTS, {
      title: true,
      size: "normal",
      soundPath: "",
      soundExt: "",
      showClass: "zoomIn",
      hideClass: "zoomOut",
      icon: true,
      msg: "",
      img: null,
      closable: true,
      delay: 10000,
      delayIndicator: true,
      closeOnClick: true,
      width: 500,
      sound: false,
      position: "bottom right",
      iconSource: "semantic",
      rounded: true,
      messageHeight: false,
      pauseDelayOnHover: true,
      onClickUrl: null,
      showAfterPrevious: false,
      continueDelayOnInactiveTab: false,
    });
  }
};
