Siepomaga.common_users = {
  new() {
    $(".change").offon("click", () => {
      $("#user_photo").trigger("click");
    });

    $("#user_sex").offon("change", (event) => {
      if (($(event.currentTarget).val() === "kobieta") && $(".user_photo_preview").data("woman")) {
        $(".user_photo_preview").attr("style", `background-image: url('${$(".user_photo_preview").data("woman")}')`);
      }
      if (($(event.currentTarget).val() === "mężczyzna") && $(".user_photo_preview").data("man")) {
        $(".user_photo_preview").attr("style", `background-image: url('${$(".user_photo_preview").data("man")}')`);
      }
    });

    new Siepomaga.SecurePassword();
  },

  create() {
    Siepomaga.common_users.new();
  },

  reset() {
    new Siepomaga.SecurePassword();
  },

  edit() {
    new Siepomaga.SecurePassword();
  },

  update() {
    Siepomaga.common_users.edit();
  },
};
