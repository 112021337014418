Siepomaga.Tabs = class Tabs {
  constructor(context = null) {
    $(".tabs", context).each((i, tabs) => {
      $(".menu .item", tabs).tab({
        onLoad(tab) {
          window.location.hash = tab;
        },
      });

      const tab = window.location.hash.replace(/[^A-z-]/g, "");
      if (tab && $(`[data-tab=${tab}]`, tabs).length) {
        $(".menu .item", tabs).tab("change tab", tab);
      } else {
        if (!$(".tabs > .menu > a.item").hasClass("active")) { $(".menu .item:first", tabs).addClass("active"); }
        $(".tab:first", tabs).addClass("active");
      }
    });
  }
};
