Siepomaga.monthly_supports_virtual_adoptions = {
  new() {
    this.initializeForm();

    const selectAnimalsLink = $("#payments-new a.remote-modal");
    selectAnimalsLink.offon("click", () => {
      const form = $("form[id=new_constant_help]");
      this.saveAdoptionAmountsToCookies(form);
    });
  },

  create() {
    this.initializeForm();
  },

  edit() {
    this.initializeForm();

    const selectAnimalsLink = $("#payments-new a.remote-modal");
    selectAnimalsLink.offon("click", () => {
      const form = $("form[id^=edit_constant_help_");
      this.saveAdoptionAmountsToCookies(form);
    });
  },

  update() {
    this.initializeForm();
  },

  saveAdoptionAmountsToCookies(form) {
    const virtualAdoptions = form.find(".amount-input");

    const data = {};

    virtualAdoptions.toArray().forEach((input) => {
      const amountInput = $(input).find("input")[0];
      const animalHashidInput = $(input).find("input")[1];

      const amount = amountInput.value;
      const animalHashid = animalHashidInput.value;

      data[animalHashid] = amount;
    });

    $.cookie("virtualAdoptionsFormData", JSON.stringify(data), { expires: 1, path: "/" });
  },

  initializeForm() {
    const amountInputs = $("input[id$=_amount]");

    const form = new Siepomaga.MonthlySupportForm(amountInputs);
    form.initialize();
  },
};
