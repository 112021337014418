import { assetUrls } from "webpackHelpers";

Siepomaga.Select2 = class Select2 {
  constructor() {
    if ($(".select2").length > 0) {
      assetUrls("select.js").then(([selectUrl]) => {
        Siepomaga.import(selectUrl, () => {
          $(".select2").each((i, el) => {
            const placeholders = {
              "cause[birthday(3i)]": "DD",
              "cause[birthday(2i)]": "MM",
              "cause[birthday(1i)]": "RRRR",
              "notification[birthday(3i)]": "DD",
              "notification[birthday(2i)]": "MM",
              "notification[birthday(1i)]": "RRRR",
            };
            const $el = $(el);
            $el.select2({
              placeholder: $el.attr("placeholder") || placeholders[$el.attr("name")] || "",
              maximumSelectionLength: $el.attr("length"),
              minimumResultsForSearch: 10,
              allowClear: !$(el).hasClass("disallow-clear"),
              closeOnSelect: !$(el).hasClass("grouped_select"),
            });
          });
        });
      });
    }

    Siepomaga.cleanOnLoad(() => {
      if ($("select.select2.select2-hidden-accessible").length > 0) {
        $("select.select2.select2-hidden-accessible").select2("destroy");
      }
    });
  }
};
