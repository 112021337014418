import "./cropper";

Siepomaga.UploadPreviewer = class UploadPreviewer {
  constructor() {
    if (!window.File || !window.FileList || !window.FileReader) { return; }

    $(document).on("change", "input.with-preview", (event) => {
      const { files } = event.currentTarget;
      const img = $(`.${$(event.currentTarget).attr("id")}_preview`);

      if ((img.length > 0) && (files.length > 0)) {
        const file = files[0];
        const reader = new FileReader();

        reader.addEventListener("load", (loadEvent) => {
          const { result } = loadEvent.target;

          if (file.type.match("image")) {
            img.attr("style", `background-image: url('${result}')`);
            img.removeAttr("data-man");
            img.removeAttr("data-woman");
          }
        });

        reader.readAsDataURL(file);
      }
    });

    $(document).on("change", "input.photo-input", (event) => {
      const { files } = event.currentTarget;
      const img = $(`img[data-photo-preview=${$(event.currentTarget).attr("id")}]`);

      if ((img.length > 0) && (files.length > 0)) {
        const file = files[0];
        const reader = new FileReader();

        reader.addEventListener("load", (loadEvent) => {
          const { result } = loadEvent.target;

          if (file.type.match("image")) {
            img.attr("src", result);

            if (img.data("crop")) {
              img.data("crop-init", null);

              new Siepomaga.Cropper(img[0], img.data("crop"));
            }
          }
        });

        reader.readAsDataURL(file);
      }
    });
  }
};
