Siepomaga.SimpleLightbox = class SimpleLightbox {
  constructor() {
    this.instances = [];
    this.init();
    Siepomaga.cleanOnLoad(() => {
      this.destroy();
    });
  }

  init() {
    $(".dropzone.image").each((i, el) => {
      const lightbox = new $.SimpleLightbox({
        $items: $(el).find("a.dz-image"),
        closeBtnCaption: "Zamknij",
        nextBtnCaption: "Następne",
        prevBtnCaption: "Poprzednie",
        loadingCaption: "Wczytuję...",
      });
      this.instances.push(lightbox);
    });
  }

  destroy() {
    $(this.instances).each((i, lightbox) => {
      lightbox.destroy();
    });

    this.instances = [];
  }

  refresh() {
    this.destroy();
    this.init();
  }
};
