Siepomaga.common_companies = {
  new() {
    new Siepomaga.SecurePassword();
  },
  create() {
    Siepomaga.common_companies.new();
  },
  edit() {
    new Siepomaga.SecurePassword();
  },
  update() {
    Siepomaga.common_companies.edit();
  },
};
