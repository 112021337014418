Siepomaga.CreditCard = class CreditCard {
  constructor() {
    //  8 - backspace
    //  13 - enter
    //  32 - space
    //  37, 39 - arrow left and right
    //  46 - delete
    //  48 - 57 - digits

    const commonPattern = "8|13|37|39|46|4[8-9]|5[0-7]";
    const numberInputRegexp = new RegExp(`^(${commonPattern})$`);
    const numberAndSpaceInputRegexp = new RegExp(`^(${commonPattern}|32)$`);
    const maxCardNumberLength = 23; // 19 digits + 4 spaces

    const notEnter = (input) => !(/^13$/).test(input);

    const preventInput = (event, regexp, maxLength) => {
      const target = event.currentTarget;
      const input = event.keyCode || event.which;
      const selectionLength = target.selectionEnd - target.selectionStart;

      return !regexp.test(input) || ($(target).val().length >= maxLength && selectionLength === 0 && notEnter(input));
    };

    $(".payu-card-number").offon("keypress", (event) => {
      if (preventInput(event, numberAndSpaceInputRegexp, maxCardNumberLength)) {
        event.preventDefault();
      }
    });

    $(".payu-card-expm").offon("keypress", (event) => {
      if (($(event.currentTarget).val().length === 1) && notEnter(event.keyCode || event.which)) {
        Siepomaga.timeout(50, () => {
          $(".payu-card-expy").focus()[0].setSelectionRange(0, 0);
        });
      }

      if (preventInput(event, numberInputRegexp, 2)) {
        event.preventDefault();
      }
    });

    $(".payu-card-expy").offon("keypress", (event) => {
      if (preventInput(event, numberInputRegexp, 4)) {
        event.preventDefault();
      }
    });

    $(".payu-card-cvv").offon("keypress", (event) => {
      if (preventInput(event, numberInputRegexp, 4)) {
        event.preventDefault();
      }
    });

    $("input[class*=payu-card-]").offon("focus click keydown", (event) => {
      if ((event.type !== "keydown") || ((event.keyCode || event.which) !== 13)) {
        $(".card-code-error").slideUp();
      }
    });

    $(".payu-card-expy, .payu-card-expm").offon("focus click keypress", (event) => {
      if ((event.type !== "keypress") || ((event.keyCode || event.which) !== 13)) {
        $(".card-date .wrapper").removeClass("field_with_errors");
        const errorDiv = $(".card-date .error");
        errorDiv.slideUp(() => errorDiv.remove());
      }
    });

    // Keypress event on Android devices do not give key code
    $(".payu-card-number").offon("input", (event) => {
      const { currentTarget } = event;

      if (!(/^[\d ]*$/).test($(currentTarget).val()) || ($(currentTarget).val().length > maxCardNumberLength)) {
        const newValue = $(currentTarget).val().replace(/[^\d ]/gi, "").substring(0, maxCardNumberLength);

        Siepomaga.timeout(50, () => {
          $(currentTarget).val(newValue);
        });
      }
    });

    $(".payu-card-expm, .payu-card-expy, .payu-card-cvv").offon("input", (event) => {
      const { currentTarget } = event;
      const maxLength = $(currentTarget).hasClass("payu-card-expm") ? 2 : 4;

      if (!(/^[\d]*$/).test($(currentTarget).val()) || ($(currentTarget).val().length > maxLength)) {
        const newValue = $(currentTarget).val().replace(/[^\d]/gi, "").substring(0, maxLength);

        Siepomaga.timeout(50, () => {
          $(currentTarget).val(newValue);
          if ($(currentTarget).hasClass("payu-card-expm") && (newValue.length === 2)) {
            $(".payu-card-expy").focus()[0].setSelectionRange(0, 0);
          }
        });
      } else if ($(currentTarget).hasClass("payu-card-expm") && ($(currentTarget).val().length === 2)) {
        $(".payu-card-expy").focus()[0].setSelectionRange(0, 0);
      }
    });

    $(".bottom button").offon("click", (event) => {
      event.preventDefault();

      const { currentTarget } = event;

      Rails.disableElement(currentTarget);

      const showCardVerificationValue = () => {
        $(".card-cvv").addClass("field_with_errors");
        if (!$(".card-cvv .error").length) {
          $("<div>", { class: "error", text: $(".payu-card-cvv").data("invalidCode") })
            .appendTo(".card-cvv")
            .slideDown();
        }
        Rails.enableElement(currentTarget);
      };

      if (!($(".payu-card-cvv").val().length > 2)) {
        showCardVerificationValue();
      }

      OpenPayU.merchantId = $("#sp-card-widget form").data("posid");

      const result = OpenPayU.Token.create({}, (res) => {
        switch (res.status.codeLiteral) {
          case "SUCCESS":
            if ($(".payu-card-cvv").val().length > 2) { // condition for payu sandbox because it ignores cvv code length
              $("#payu_value", window.parent.document).val(res.data.token);
              $("#payu_form", window.parent.document).submit();
            }
            break;
          case "CARD_INVALID_CVV":
            showCardVerificationValue();
            break;
          default:
            if (!$(".card-cvv .error").length) {
              $(".card-code-error").slideDown();
              Rails.enableElement(currentTarget);
            }
            break;
        }
      });

      if (result !== true) {
        if (result["card.expirationDate"]) { $(".card-expm, .card-expy").addClass("field_with_errors"); }
        if (result["card.number"]) { $(".card-number").addClass("field_with_errors"); }

        if (result["card.expirationDate"] && !$(".card-date .error").length) {
          $("<div>", { class: "error card-date-error", text: $(".card-date-form").data("invalidDate") })
            .appendTo(".card-date")
            .slideDown();
        }

        if (result["card.number"] && !$(".card-number .error").length) {
          $("<div>", { class: "error", text: $(".payu-card-number").data("invalidFormat") })
            .appendTo(".card-number")
            .slideDown();
        }

        Rails.enableElement(currentTarget);
      }
    });
  }
};
