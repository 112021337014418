Siepomaga.home = {
  player: null,

  index() {
    this.slideshow();
    this.updateStats();
  },

  slideshow() {
    $("#dots-before-js").hide();
    const slickSlideshow = $("#homepage-slideshow").slick({
      arrows: false,
      dots: true,
      centerMode: true,
      variableWidth: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 1000,
      pauseOnDotsHover: true,
      slidesToShow: 1,
    });

    $("#homepage-slideshow").offon("click", ".slick-slide[aria-hidden=true]", (event) => {
      event.preventDefault();
      const index = $(event.target).closest(".slick-slide").data("slick-index");
      $("#homepage-slideshow").slick("slickGoTo", index);
    });

    Siepomaga.cleanOnLoad(() => {
      slickSlideshow.slick("unslick");
    });
  },

  updateStats() {
    Siepomaga.interval(5000, () => {
      let ajax = null;

      if (document.hidden) { return; }
      if (ajax) { ajax.abort(); }

      const host = $("#homepage-causes").data("statsHost");
      ajax = $.get({
        url: `https://${host}/statystyki`,
        timeout: 3000,
        success(data) {
          if (parseFloat(data.total_amount) <= parseFloat($("#total_amount").data("value"))) { return; }

          new Siepomaga.CountUp("#total_amount", data.total_amount, " zł");
          new Siepomaga.CountUp("#total_users", data.total_users);
        },
      });

      Siepomaga.cleanOnLoad(() => {
        if (ajax) { ajax.abort(); }
      });
    });
  },
};
