// Order here is important - init has to be first and init_callbacks uses functions from layout files

import Turbolinks from "turbolinks";

import "./libs";
import "./siepomaga/init";
import "./layouts/common";
import "./layouts/animals";
import "./siepomaga/init_callbacks";

// this enhances jQuery with the offon method, so we need to require it before other files
require("./siepomaga/components/offon");

// we require all files in components first, so they are available in siepomaga files
// but we need to take care of the order of requiring files inside the components directory manually
// dependencies between components should be handled by the components themselves
requireAll(require.context("./siepomaga/components", true, /^(?!.*offon).*\.js$/));
requireAll(require.context("./siepomaga", true, /^(?!.*components).*\.js$/));
requireAll(require.context("./animals", true, /\.(js)$/));

// Turbolinks - always at the end
Turbolinks.start();
