Siepomaga.animals_causes = {
  show() {
    $("p:has(iframe[data-vertical=true])").addClass("has-vertical-video");
    $("p:has(iframe[data-height-ratio])").each((i, el) => {
      $(el).addClass("has-height-ratio");
      const ratio = $(el).find("iframe").data("height-ratio");
      if ($(el).hasClass("has-vertical-video")) {
        $(el).css("padding-bottom", `${$(el).find("iframe").width() * ratio}px`);
      } else {
        $(el).css("padding-bottom", `${ratio * 100}%`);
      }
    });

    let slickSlideshowDesktop = null;

    this.updateStats();
    this.hideCensoredPhotos();

    Siepomaga.timeout(1000, () => {
      $(".progressbar .sp-progressbar.success").addClass("success-again");
    });

    $("iframe[data-src]").visibility({
      onTopVisible() {
        $(this).attr("src", $(this).data("src"));
      },
    });

    $("#show-updates").offon("click", (event) => {
      const { currentTarget } = event;
      event.preventDefault();

      $(currentTarget).hide();
      $(currentTarget).parent().next(".visibility-hidden").css({ visibility: "visible", height: "auto" })
        .hide()
        .fadeIn("slow");
      $(".visibility-hidden iframe[data-src]").visibility({
        onTopVisible() {
          $(currentTarget).attr("src", $(currentTarget).data("src"));
        },
      });
    });

    $(".show-description").offon("click", (event) => {
      const { currentTarget } = event;
      event.preventDefault();

      $(currentTarget).hide();
      $(currentTarget).parent().next(".hidden").show();
      $("#right-column .ui.sticky:visible").sticky("refresh");
      $("iframe[data-src]").visibility({
        onTopVisible() {
          if (!$(currentTarget).attr("src")) { $(currentTarget).attr("src", $(currentTarget).data("src")); }
        },
      });
    });

    Siepomaga.causes.refreshPayments();

    Siepomaga.onlyDesktop({
      create() {
        $("#right-column .ui.sticky").sticky({
          offset: 20,
          context: "#column-container .ten.wide.column",
          observeChanges: "#left-column",
        });

        $("#left-column img").on("load", () => {
          $("#right-column .ui.sticky").sticky("refresh");
        });

        const slideshowElement = $(".causes-show #right-column .virtual-adoptions-widget");
        if (slideshowElement.length === 0) { return; }

        slickSlideshowDesktop = new Siepomaga.AnimalCarousel(slideshowElement);
      },

      destroy() {
        $("#right-column .ui.sticky").sticky("destroy");
        $("#left-column img").off("load");

        if (slickSlideshowDesktop) {
          slickSlideshowDesktop.destructor();
        }
      },
    });

    let slickSlideshowMobile = null;

    const handleScroll = () => {
      if (!$("#navigation .mobile-menu:visible").length || !$("#left-column .big.sp-button").length) { return; }

      // eslint-disable-next-line max-len
      const bigSpButtonYPosition = $("#left-column .big.sp-button").offset().top + $("#left-column .big.sp-button").outerHeight() - $("#navigation").outerHeight();

      if ((window.scrollY + window.innerHeight) >= $(document).height()) {
        $(".mobile-donation-buttons").removeClass("active");
      } else if (window.scrollY > bigSpButtonYPosition) {
        $(".mobile-donation-buttons").addClass("active");
      } else {
        $(".mobile-donation-buttons").removeClass("active");
      }
    };

    handleScroll();

    Siepomaga.onlyTablet({
      create() {
        $(window).on("scroll", handleScroll);

        const slideshowElement = $(".causes-show .mobile-navigation .virtual-adoptions-widget");
        if (slideshowElement.length === 0) { return; }

        slickSlideshowMobile = new Siepomaga.AnimalCarousel(slideshowElement);
      },

      destroy() {
        $(window).off("scroll", handleScroll);
        $(".mobile-donation-buttons").removeClass("active");

        if (slickSlideshowMobile) {
          slickSlideshowMobile.destructor();
        }
      },
    });

    const showVideo = () => {
      $(".main-video a").hide();
      const iframe = $("<iframe frameborder=\"0\" allowfullscreen=\"allowfullscreen\" />");
      iframe.attr("src", $(".main-video a").data("video-url"));
      $(".main-video").append(iframe);
    };

    $(".main-video a").offon("click", (event) => {
      event.preventDefault();
      showVideo();
    });

    $("a[data-tab='wplaty']").offon("click", (_event) => {
      $("#cause-payment-loader").visibility("refresh");
    });

    if ($(".tab.active[data-tab!='wplaty']").length) {
      $(".tabs .ui.menu").removeClass("hidden");
    }

    $(".tabs#payments").visibility({
      onTopVisible() {
        const url = $("#cause-payment-loader").data("url");
        Siepomaga.getScript(url, () => {
          $(".tabs .ui.menu").removeClass("hidden");
        });
      },
    });
  },

  updateStats() {
    if ($(".causes-show").data("url")) {
      let prevState = {};
      Siepomaga.interval(5000, () => {
        let ajax = null;

        if (document.hidden) { return; }
        if (ajax) { ajax.abort(); }

        ajax = $.get({
          url: $(".causes-show").data("url"),
          success(data) {
            $(["#cause-content .mobile-navigation", "#right-column"]).each((i, id) => {
              if (parseFloat(data.amount) <= parseFloat($(`${id} .amount`).data("value"))) { return; }

              new Siepomaga.CountUp(`${id} .payments-count`, data.payments_count);
              new Siepomaga.CountUp(`${id} .amount`, data.amount, " zł");
              const currentPercentageValue = $(`${id} .progressbar .percentage`).data("value");
              if (data.percentage !== currentPercentageValue) {
                $(`${id} .progressbar .bar`).animate({ width: `${data.percentage}%` }, 3000);
                if ((currentPercentageValue < 100) && (data.percentage >= 100) && !$(".black-white").length) {
                  $(`${id} .progressbar .sp-progressbar`).addClass("success-new");
                }
              }
              new Siepomaga.CountUp(`${id} .progressbar .percentage`, data.percentage, "%");
              if ($(`${id} .amount-left`).length) {
                new Siepomaga.CountUp(`${id} .amount-left`, data.amount_left, " zł", (() => {
                  if ($(`${id} .still-needed`).length && (data.amount_left === "0.0")) {
                    $(`${id} .still-needed`).fadeOut("slow");
                    $(`${id} .text`).addClass("success");
                  }
                }));
              }
            });

            if ($(".tabs#payments").is(":visible")) {
              if (data.payments_count > prevState.payments_count) {
                const newPaymentsCount = data.payments_count - prevState.payments_count;
                const paymentsLink = $("#show-new-payments");
                paymentsLink.attr(
                  "data-new-payments",
                  parseInt(paymentsLink.attr("data-new-payments"), 10) + newPaymentsCount,
                );
                $("#show-new-payments > span").html(`(${paymentsLink.attr("data-new-payments")})`);
                if ($(".new-payments-info").is(":hidden")) { $(".new-payments-info").transition("fade down"); }
              }

              prevState = data;
            }
          },
        });

        Siepomaga.cleanOnLoad(() => {
          if (ajax) { ajax.abort(); }
        });
      });
    }
  },

  hideCensoredPhotos() {
    $("img[data-censored]").each((i, img) => {
      delete img.dataset.censored;

      img.dataset.src = img.src;

      // eslint-disable-next-line max-len
      $(img).replaceWith(`<span class="sp-censored-image"><span class="cover">${img.outerHTML}<span><big>Zdjęcie nieodpowiednie dla osób wrażliwych</big><br/>Kliknij tutaj, żeby je wyświetlić</span></span>${img.outerHTML}</span>`);
    });
  },
};
