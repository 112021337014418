Siepomaga.certificate_email = {
  show() {
    Siepomaga.animals_percentages.show();
  },

  download_certificate() {
    const link = document.getElementById("download-link");
    if (link) {
      Siepomaga.timeout(1000, () => {
        link.click();
      });
    }
  },
};
