Siepomaga.faq = {
  show() {
    new Siepomaga.Accordion();

    $(".faq__tabs .faq__tab").tab({
      selectors: {
        tabs: ".tab",
        parent: ".ui:not(.faq__tabs)",
      },
    });
  },
};
