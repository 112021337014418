import "./slick_slideshow";

Siepomaga.AnimalCarousel = class AnimalCarousel {
  destructor() {
    if (this.slideshow == null) { return; }

    this.slideshow.destructor();
    this.slideshow = null;
  }

  constructor(slideshowElement) {
    const animalCarouselOpts = {
      arrows: true,
      appendArrows: slideshowElement.find(".avatar"),
      prevArrow: slideshowElement.find(".prevArrow"),
      nextArrow: slideshowElement.find(".nextArrow"),
      pauseOnHover: true,
    };

    this.slideshow = new Siepomaga.SlickSlideshow(slideshowElement.find(".avatar-carousel"), animalCarouselOpts);

    const slidingFields = ["name", "donators"];
    const slidingHrefs = ["virtualAnimalHref"];

    this.slideshow.initialize(slidingFields, slidingHrefs);
  }
};
