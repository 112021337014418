import { constantHelpDefaultAmount } from "railsVariables";

Siepomaga.monthly_supports_common = {
  isAmountValid(value) {
    return !value || value.match(/^\d{1,7}([,.]\d?\d?)?$/);
  },

  sumInputsAmount(inputs) {
    if (inputs.length === 0) { return 0; }

    const initialValue = 0;
    return inputs.toArray().reduce(
      (accumulatedSum, input) => accumulatedSum + Number($(input).val().replace(/,/g, ".") || "0"),
      initialValue,
    );
  },

  toggleAmountTooltips(input) {
    const defaultAmountTooltip = input.siblings("#constant_help_default_amount_tooltip");

    const suggestedAmountTooltip = input.siblings("#constant_help_suggested_amount");
    if (suggestedAmountTooltip.length > 0) {
      suggestedAmountTooltip.removeClass("visible");
    }

    const amount = input.val();
    if (amount === constantHelpDefaultAmount) {
      defaultAmountTooltip.addClass("visible");
    } else {
      defaultAmountTooltip.removeClass("visible");
    }
  },

  handleChargeDateInput(chargeDayInput) {
    chargeDayInput.offon("change", (event) => {
      const el = $(event.currentTarget);
      let chargeDay = el.val();

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const nextDate = new Date();
      nextDate.setHours(0, 0, 0, 0);

      if ((today.getDate() >= el.val()) || el.data("chargedThisMonth")) {
        nextDate.setMonth(nextDate.getMonth() + 1);
      }

      const nextMonthChargeDay = new Date(nextDate.getFullYear(), nextDate.getMonth(), 0).getDate();
      chargeDay = Math.min(nextMonthChargeDay, chargeDay);

      nextDate.setDate(chargeDay);

      $("#next-charge-amount").html((nextDate - today) / (1000 * 60 * 60 * 24));
    });
  },
};
