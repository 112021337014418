Siepomaga.Accordion = class Accordion {
  constructor(newTarget) {
    let target = newTarget;
    if (target == null) { target = ".accordion"; }
    const componentClass = "c-common--accordion";

    const selectors = {
      title: `.${componentClass}-header`,
      content: `.${componentClass}-content`,
      trigger: `.${componentClass}-header`,
    };

    const openingAnimationDurationMs = 300;

    $(target).accordion({
      duration: openingAnimationDurationMs,
      exclusive: false, // multiple accordions can be opened at same time.
      selector: selectors,
      className: {
        active: `${componentClass}-active`,
        animating: `${componentClass}-animating`,
      },
    });
  }
};
