Siepomaga.FitFont = class FitFont {
  constructor() {
    if (!$(".adjust-font").length) { return; }
    this.adjustFonts();

    $(window).offon("resize", () => {
      this.adjustFonts();
    });
  }

  adjustFonts() {
    $(".adjust-font").each((i, el) => {
      const maxFontSize = $(this).data("maxfontsize") || 32;
      const minFontSize = $(this).data("minfontsize") || 10;

      const currentFontSize = (element) => parseInt($(element).css("font-size").slice(0, -2), 10);

      const decreaseFontSize = () => {
        const currentSize = currentFontSize(el);
        const smallerFontSize = currentSize >= minFontSize ? currentSize - 1 : minFontSize;
        $(el).css("font-size", `${smallerFontSize}px`);
      };

      const increaseFontSize = () => {
        const currentSize = currentFontSize(el);
        const biggerFontSize = currentSize <= maxFontSize ? currentSize + 1 : maxFontSize;
        $(el).css("font-size", `${biggerFontSize}px`);
      };

      const contentHeight = (container) => {
        let contentH = 0;
        $(container).children().each((j, element) => {
          contentH += $(element).outerHeight(true);
        });
        return Math.round(contentH);
      };

      let contentH = contentHeight(el);
      if (el.scrollHeight > el.offsetHeight) {
        while (el.scrollHeight > el.offsetHeight) {
          decreaseFontSize();
          if (currentFontSize(el) < minFontSize) { break; }
        }
      } else {
        contentH = contentHeight(el);
        while (el.offsetHeight >= contentH) {
          increaseFontSize();
          if (currentFontSize(el) > maxFontSize) { break; }
          contentH = contentHeight(el);
        }
        decreaseFontSize();
      }
    });
  }
};
