import { applepayMerchantIdSetting } from "railsVariables";

Siepomaga.ApplePay = {
  available(callback) {
    if (!window.ApplePaySession) { return; }
    if (!ApplePaySession.canMakePayments()) { return; }

    ApplePaySession.canMakePaymentsWithActiveCard(applepayMerchantIdSetting).then((canMakePayments) => {
      if (canMakePayments) { callback(); }
    });
  },

  createSession(amount) {
    const params = {
      countryCode: "PL",
      currencyCode: "PLN",
      supportedNetworks: ["visa", "masterCard"],
      merchantCapabilities: ["supports3DS"],
      total: { label: "Fundacja Ratujemy.pl Darowizna", amount },
    };

    const session = new ApplePaySession(3, params);

    session.onvalidatemerchant = (event) => {
      $.ajax({
        method: "POST",
        url: "/applepay/validate",
        dataType: "json",
        data: {
          url: event.validationURL,
        },
        success(response) {
          session.completeMerchantValidation(response);
        },
      });
    };

    session.oncancel = () => {
      this.cartId = null;
      this.session = null;
      Turbolinks.visit("/platnosc/nowa");
    };

    session.onpaymentauthorized = (event) => {
      if (!this.cartId) { return; }

      $.ajax({
        method: "POST",
        url: "/applepay/create",
        dataType: "json",
        data: {
          cart_id: this.cartId,
          authorizationCode: btoa(JSON.stringify(event.payment.token.paymentData)),
        },
        success: (response) => {
          this.cartId = null;

          if (response.success) {
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
            Turbolinks.visit(response.url);
          } else {
            session.completePayment(ApplePaySession.STATUS_FAILURE);
            Turbolinks.visit("/platnosc/nowa");
          }
        },
      });
    };

    this.session = session;

    return true;
  },

  startSession(cartId) {
    if (!this.session) { return false; }

    this.session.begin();
    this.cartId = cartId;
    this.session = null;

    return true;
  },
};
