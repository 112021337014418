Siepomaga.commonOnce = () => {
  setInterval(() => new Siepomaga.TimeUpdater(), 30000); // 30 seconds

  new Siepomaga.Form();
  new Siepomaga.Socialmedia();
  new Siepomaga.OneSignal();
  new Siepomaga.RemoteModal();
  new Siepomaga.EventTracking();
  new Siepomaga.FlashMessages.DefaultConfig();

  Siepomaga.currentLayoutInitOnce();

  $(document).on("click", ".login-link", (event) => {
    $(event.currentTarget).addClass("loading");
  });

  $(document).on("click", "a.open-modal", (event) => {
    const { currentTarget } = event;
    event.preventDefault();

    if (($(currentTarget).attr("href") === "#login-modal") && !$.cookie("redirect")) {
      const date = new Date();
      date.setTime(date.getTime() + (10 * 60 * 1000)); // 10 minutes in milliseconds
      $.cookie("redirect", ($(currentTarget).data("redirect") || window.location.href), { expires: date, path: "/" });
    }

    if (($(currentTarget).attr("href") === "#share-methods-modal") && navigator.share && (window.innerWidth < 768)) {
      navigator.share({ url: window.location.href }).catch((error) => {
        if (error instanceof Error && (error.name !== "AbortError")) {
          throw error;
        }
      });
    } else {
      $(".ui.modal.active").addClass("keep-modal");

      const inlineModal = $($(currentTarget).attr("href"));

      inlineModal.modal({
        onHide() {
          if (!inlineModal.hasClass("keep-modal")) {
            $(".ui.modal.keep-modal").last().removeClass("keep-modal").modal("show");
          }
        },
      });

      inlineModal.modal("show");
    }
  });

  $(window).on("hashchange", () => {
    const tab = window.location.hash.replace(/[^A-z-]/g, "");

    if (tab && $(`[data-tab=${tab}]`).length) {
      $(".tabs .menu .item").tab("change tab", tab);
    } else {
      $("[data-tab]").removeClass("active");
      $(".tabs .menu .item:first").addClass("active");
      $(".tabs .tab:first").addClass("active");
    }
  });

  Siepomaga.import("https://www.googletagmanager.com/gtag/js?id=AW-954016581", () => {
    window.dataLayer = window.dataLayer || [];

    window.gtag = (...args) => window.dataLayer.push(args);

    window.gtag("js", new Date());

    window.gtag("config", "AW-867944201");
  });
};

Siepomaga.common = () => {
  new Siepomaga.Livesearch();
  new Siepomaga.TimeUpdater();
  new Siepomaga.Cookies();
  new Siepomaga.SocialmediaButtons();
  new Siepomaga.CountDown();
  new Siepomaga.Select2();
  new Siepomaga.Slideout();
  new Siepomaga.Popup();
  new Siepomaga.FlashMessages();
  new Siepomaga.DotDotDot();

  Siepomaga.currentLayoutInit();

  $("#navigation .ui.dropdown").dropdown();
  $(".ui.bottom.sticky").sticky();

  // Modals after back fix
  if ($(".ui.active.modal").length) {
    $(".ui.active.modal").modal({
      onHidden() {
        if ($(this).data("remote-modal")) {
          $(this).remove();
        }
      },
    });
    $(".ui.active.dimmer").dimmer({
      onHide() {
        $(this).find(".ui.active.modal").modal("hide");
      },
    });
  }

  // Form errors hiding after click
  new Siepomaga.FormErrorsHandler();

  const handleScroll = () => {
    if (!$("#navigation #mobile-menu-button:visible").length) { return; }

    if (window.scrollY > 70) {
      $("#navigation").addClass("scrolled");
    } else if (!$(".slideout-open").length) {
      $("#navigation").removeClass("scrolled");
    }
  };

  handleScroll();

  Siepomaga.onlyTablet({
    create() {
      $(window).on("scroll", handleScroll);
    },
    destroy() {
      $(window).off("scroll", handleScroll);
    },
  });

  Array.prototype.forEach.call(document.querySelectorAll("button.sp-button"), (el) => {
    if (!el.dataset.disableWith && (el.dataset.withoutDisable === undefined)) {
      const cloned = el.cloneNode(true);
      const icon = cloned.querySelector("span.icon i.icon");
      if (icon) {
        icon.outerHTML = "<i class=\"notched circle loading icon\"></i>";
        el.dataset.disableWith = cloned.innerHTML;
      } else {
        el.dataset.disable = true;
      }
    }
  });
};
