Siepomaga.common_blik = {
  new() {
    Siepomaga.common_carts.blik();
  },

  create() {
    Siepomaga.common_carts.blik();
  },

  show() {
    Siepomaga.timeout(4000, () => {
      this.checkIfSuccess();
    });
  },

  checkIfSuccess() {
    Siepomaga.timeout(2000, () => {
      $.getJSON("", (response) => {
        if (response.success) {
          this.handleSuccess();
        } else {
          this.checkIfSuccess();
        }
      }).fail(() => {
        this.checkIfSuccess();
      });
    });
  },

  handleSuccess() {
    $(".blik-phone").removeClass("sp-loader");
    $(".blik-phone").addClass("success");

    Siepomaga.timeout(1000, () => {
      Turbolinks.visit("/platnosc/success?confirmed=true");
    });
  },
};
