class TimeUpdater {
  constructor() {
    $("[data-time]").each((i, el) => {
      let message = "";
      const seconds = parseInt(((new Date()) - Date.parse($(el).data("time"))) / 1000, 10);
      if (seconds < 60) {
        message = "przed chwilą";
      } else {
        const minutes = parseInt(seconds / 60, 10);
        if (minutes < 60) {
          if (minutes === 1) {
            message = `${minutes} minuta temu`;
          } else if (([2, 3, 4].includes(minutes % 10)) && !([12, 13, 14].includes(minutes % 100))) {
            message = `${minutes} minuty temu`;
          } else {
            message = `${minutes} minut temu`;
          }
        } else {
          const hours = parseInt(minutes / 60, 10);
          if (hours < 24) {
            if (hours === 1) {
              message = `${hours} godzina temu`;
            } else if (([2, 3, 4].includes(hours % 10)) && !([12, 13, 14].includes(hours % 100))) {
              message = `${hours} godziny temu`;
            } else {
              message = `${hours} godzin temu`;
            }
          } else {
            const days = parseInt(hours / 24, 10);
            if (days < 365) {
              if (days === 1) {
                message = `${days} dzień temu`;
              } else {
                message = `${days} dni temu`;
              }
            } else {
              const years = parseInt(days / 365, 10);
              if (years === 1) {
                message = `${years} rok temu`;
              } else if (([2, 3, 4].includes(years % 10)) && !([12, 13, 14].includes(years % 100))) {
                message = `${years} lata temu`;
              } else {
                message = `${years} lat temu`;
              }
            }
          }
        }
      }

      $(el).html(message);
    });
  }
}

Siepomaga.TimeUpdater = TimeUpdater;
