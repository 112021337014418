Siepomaga.causes = {
  show() {
    $("p:has(iframe[data-vertical=true])").addClass("has-vertical-video");
    $("p:has(iframe[data-height-ratio])").each((i, el) => {
      $(el).addClass("has-height-ratio");
      const ratio = $(el).find("iframe").data("height-ratio");
      if ($(el).hasClass("has-vertical-video")) {
        $(el).css("padding-bottom", `${270 * ratio}px`);
      } else {
        $(el).css("padding-bottom", `${ratio * 100}%`);
      }
    });

    this.updateStats();
    this.hideCensoredPhotos();
    this.refreshPayments();

    Siepomaga.timeout(1000, () => {
      $(".progressbar .sp-progressbar.success").addClass("success-again");
    });

    $("iframe[data-src]").visibility({
      onTopVisible() {
        $(this).attr("src", $(this).data("src"));
      },
    });

    $("#show-updates").offon("click", (event) => {
      const { currentTarget } = event;
      event.preventDefault();

      $(currentTarget).hide();
      $(currentTarget).parent().next(".visibility-hidden").css({ visibility: "visible", height: "auto" })
        .hide()
        .fadeIn("slow");
      $(".visibility-hidden iframe[data-src]").visibility({
        onTopVisible() {
          $(currentTarget).attr("src", $(currentTarget).data("src"));
        },
      });
    });

    $("#cause_urgent, #cause_passed_away").offon("change", (event) => {
      $(event.currentTarget.form).submit();
    });

    $(".show-description").offon("click", (event) => {
      const { currentTarget } = event;
      event.preventDefault();

      $(currentTarget).hide();
      $(currentTarget).parent().next(".hidden").show();
      $("#right-column .ui.sticky:visible").sticky("refresh");
      $("iframe[data-src]").visibility({
        onTopVisible() {
          if (!$(currentTarget).attr("src")) { $(currentTarget).attr("src", $(currentTarget).data("src")); }
        },
      });
    });

    Siepomaga.onlyDesktop({
      create() {
        $("#right-column .ui.sticky").sticky({
          offset: 20,
          context: "#column-container .ten.wide.column",
          observeChanges: "#left-column",
        });

        $("#left-column img").on("load", () => {
          $("#right-column .ui.sticky").sticky("refresh");
        });
      },
      destroy() {
        $("#right-column .ui.sticky").sticky("destroy");
        $("#left-column img").off("load");
      },
    });

    const handleScroll = () => {
      const button = $("#left-column .big.sp-button");
      if (!$("#navigation .mobile-menu:visible").length || !button.length) { return; }

      if ((window.scrollY + window.innerHeight) >= $(document).height()) {
        $(".mobile-donation-buttons").removeClass("active");
      } else if (window.scrollY > ((button.offset().top + button.outerHeight()) - $("#navigation").outerHeight())) {
        $(".mobile-donation-buttons").addClass("active");
      } else {
        $(".mobile-donation-buttons").removeClass("active");
      }
    };

    handleScroll();

    Siepomaga.onlyTablet({
      create() {
        $(window).on("scroll", handleScroll);
      },
      destroy() {
        $(window).off("scroll", handleScroll);
        $(".mobile-donation-buttons").removeClass("active");
      },
    });

    const showVideo = () => {
      $(".main-video a").hide();
      const iframe = $("<iframe frameborder=\"0\" allowfullscreen=\"allowfullscreen\" />");
      iframe.attr("src", $(".main-video a").data("video-url"));
      $(".main-video").append(iframe);
    };

    $(".main-video a").offon("click", (event) => {
      event.preventDefault();
      showVideo();
    });

    $(".cause-verified-modal").offon("click", (_event) => {
      $("#cause-verified-modal").modal("hide");
    });

    $("a[data-tab='wplaty']").offon("click", (_event) => {
      $("#cause-payment-loader").visibility("refresh");
    });

    if ($(".tab.active[data-tab!='wplaty']").length) {
      $(".tabs .ui.menu").removeClass("hidden");
    }

    $(".tabs#payments").visibility({
      onTopVisible() {
        const url = $("#cause-payment-loader").data("url");
        Siepomaga.getScript(url, () => {
          $(".tabs .ui.menu").removeClass("hidden");
        });
      },
    });
  },

  live() {
    $(".sp-payment").show();

    Siepomaga.interval(5000, () => {
      let ajax = null;

      if (ajax) { ajax.abort(); }

      ajax = $.get({
        url: "",
        dataType: "json",
        data: {
          last_payment_time: $(".live-payments .sp-payment-time small:first").data("time"),
        },
        cache: false,
        timeout: 3000,
        success(data) {
          if (parseFloat(data.amount) <= parseFloat($(".cause-progressbar .amount").data("value"))) { return; }

          new Siepomaga.CountUp(".payments-count", data.payments_count);
          new Siepomaga.CountUp(".cause-progressbar .amount", data.amount, " zł");
          if (data.percentage !== $(".progressbar .percentage").data("value")) {
            $(".progressbar .bar").animate({ width: `${data.percentage}%` }, 3000);
            if (($(".progressbar .percentage").data("value") < 100) && (data.percentage >= 100)) {
              $(".progressbar .sp-progressbar").addClass("success-new");
            }
          }
          new Siepomaga.CountUp(" .progressbar .percentage", data.percentage, "%");
          if ($(" .amount-left").length) {
            new Siepomaga.CountUp(".amount-left", data.amount_left, " zł");
          }
          $(".live-payments").prepend(data.payments);

          new Siepomaga.TimeUpdater();
          $(".sp-payment:hidden").slideDown();

          if (data.percentage >= 100) {
            Siepomaga.timeout(3500, () => {
              $(".sp-progressbar .text").addClass("success");
              $(".still-needed").html("Cel osiągnięty");
            });
          }
        },
      });

      Siepomaga.cleanOnLoad(() => {
        if (ajax) { ajax.abort(); }
      });
    });
  },

  updateStats() {
    if ($(".causes-show").data("url")) {
      let prevState = {};
      Siepomaga.interval(5000, () => {
        let ajax = null;

        if (document.hidden) { return; }
        if (ajax) { ajax.abort(); }

        ajax = $.get({
          url: $(".causes-show").data("url"),
          success(data) {
            $(["#cause-content .mobile-navigation", "#right-column"]).each((i, id) => {
              if (!$(id).length) { return; }
              if (parseFloat(data.amount) <= parseFloat($(`${id} .amount`).data("value"))) { return; }

              new Siepomaga.CountUp(`${id} .payments-count`, data.payments_count);
              new Siepomaga.CountUp(`${id} .amount`, data.amount, " zł");
              const currentPercentageValue = $(`${id} .progressbar .percentage`).data("value");
              if (data.percentage !== currentPercentageValue) {
                $(`${id} .progressbar .bar`).animate({ width: `${data.percentage}%` }, 3000);
                if ((currentPercentageValue < 100) && (data.percentage >= 100) && !$(".black-white").length) {
                  $(`${id} .progressbar .sp-progressbar`).addClass("success-new");
                }
              }
              if ($(`${id} .progressbar .percentage`).length) {
                new Siepomaga.CountUp(`${id} .progressbar .percentage`, data.percentage, "%");
              }
              if ($(`${id} .amount-left`).length) {
                new Siepomaga.CountUp(`${id} .amount-left`, data.amount_left, " zł", (() => {
                  if ($(`${id} .still-needed`).length && (data.amount_left === "0.0")) {
                    $(`${id} .still-needed`).fadeOut("slow");
                    $(`${id} .text`).addClass("success");
                  }
                }));
              }
            });

            if ($(".tabs#payments").is(":visible")) {
              if (data.payments_count > prevState.payments_count) {
                const newPaymentsCount = data.payments_count - prevState.payments_count;
                const paymentsLink = $("#show-new-payments");
                paymentsLink.attr(
                  "data-new-payments",
                  parseInt(paymentsLink.attr("data-new-payments"), 10) + newPaymentsCount,
                );
                $("#show-new-payments > span").html(`(${paymentsLink.attr("data-new-payments")})`);
                if ($(".new-payments-info").is(":hidden")) { $(".new-payments-info").transition("fade down"); }
              }

              prevState = data;
            }
          },
        });

        Siepomaga.cleanOnLoad(() => {
          if (ajax) { ajax.abort(); }
        });
      });
    }
  },

  hideCensoredPhotos() {
    $("img[data-censored]").each((i, img) => {
      delete img.dataset.censored;

      img.dataset.src = img.src;

      // eslint-disable-next-line max-len
      $(img).replaceWith(`<span class="sp-censored-image"><span class="cover">${img.outerHTML}<span><big>Zdjęcie nieodpowiednie dla osób wrażliwych</big><br/>Kliknij tutaj, żeby je wyświetlić</span></span>${img.outerHTML}</span>`);
    });
  },

  preview() {
    new Siepomaga.CountDown();
  },

  refreshPayments() {
    $("#show-new-payments").offon("click", (event) => {
      event.preventDefault();

      if (!($(event.currentTarget).attr("data-new-payments") > 0)) { return; }

      $("a#show-new-payments").hide();
      $("span#loader").show();

      $("#show-new-payments").data("refresh", true);
      $.ajax({
        url: "",
        cache: true,
        dataType: "script",
        data: {
          last_payment_time: "",
        },
        success() {
          $("#show-new-payments").attr({ "data-new-payments": 0 });
          $("#show-new-payments").data("refresh", false);
        },
      });
    });
  },
};
