Siepomaga.currentLayoutInitOnce = () => {
  new Siepomaga.UploadPreviewer();
  new Siepomaga.Favorites();
  new Siepomaga.FlashMessages.DefaultConfig();

  $(document).on("click", ".settings-box .title", (event) => {
    $(event.target).next(".content").slideToggle("fast", () => {
      $("#right-column .ui.sticky").sticky("refresh");
    });
    $(event.target).parent(".settings-box").toggleClass("opened");
  });

  $(document).on("click", ".sp-censored-image .cover", (event) => {
    $(event.currentTarget).remove();
  });

  $(document).on("click", "[data-slide-toggle]", (event) => {
    const { currentTarget } = event;
    event.preventDefault();

    $(currentTarget).toggleClass("active");
    $(currentTarget).find("i.angle.down").toggleClass("opened");
    $($(currentTarget).data("slide-toggle")).slideToggle({
      start: () => {
        if (!$(currentTarget).data("slide-toggle-complete-element")) { return; }

        if ($(currentTarget).hasClass("active")) {
          $($(currentTarget).data("slide-toggle-complete-element")).removeClass(
            $(currentTarget).data("slide-toggle-complete-toggle"),
          );
        }
      },

      complete: () => {
        if (!$(currentTarget).data("slide-toggle-complete-element")) { return; }

        if (!$(currentTarget).hasClass("active")) {
          $($(currentTarget).data("slide-toggle-complete-element")).addClass(
            $(currentTarget).data("slide-toggle-complete-toggle"),
          );
        }
      },
    });
  });

  $(document).on("click", "[data-toggle]", (event) => {
    event.preventDefault();
    $($(event.currentTarget).data("toggle")).toggle();
  });
};

Siepomaga.currentLayoutInit = () => {
  new Siepomaga.DotDotDot();
  new Siepomaga.Tinymce();
  new Siepomaga.Tabs();
  new Siepomaga.FlashMessages();

  $(".sortable.table").tablesort();
  $(".ui.accordion").accordion();

  $("[data-crop][data-crop-init]").each((i, el) => {
    new Siepomaga.Cropper(el, $(el).data("crop"));
  });

  $("[data-copy]").offon("click", (event) => {
    const { currentTarget } = event;
    event.preventDefault();

    const $copyLabel = $(".copyLabel:first", event.currentTarget);
    const copyLabelText = $copyLabel.text();

    const textToCopy = $(currentTarget).data("copy");

    if (!navigator.clipboard) {
      const $input = $("<input>");
      $("body").append($input);
      $input.val(textToCopy).select();
      document.execCommand("copy");
      $input.remove();
    } else {
      navigator.clipboard.writeText(textToCopy);
    }

    if (copyLabelText && (copyLabelText !== "Skopiowano!")) {
      $copyLabel.text("Skopiowano!");
      Siepomaga.timeout(2000, () => {
        $copyLabel.text(copyLabelText);
      });
    }
  });

  $(".ui.embed").embed();
  Siepomaga.cleanOnLoad(() => {
    $(".ui.embed").embed("destroy");
  });
};
