import { assetUrls } from "webpackHelpers";

Siepomaga.common_carts = {
  new() {
    Siepomaga.common_carts.new_common();

    if (window.ga) {
      window.ga((tracker) => {
        $("#cart_google_tracking_value").val(tracker.get("clientId"));
      });
    }

    if ((typeof window.fpPromise !== "undefined") && window.fpPromise) {
      window.fpPromise.then((fp) => fp.get()).then((result) => {
        $("#cart_secure_hash").val(result.visitorId);
      });
    }
  },

  create() {
    Siepomaga.common_carts.new_common();
  },

  success() {
    new Siepomaga.FitFont();

    Siepomaga.waitUntil((() => window.gtag), () => {
      window.gtag("event", "conversion", { send_to: "AW-954016581/lfXWCOim-ooBEMXG9MYD" });
    });

    if ($("#animal-frame").length) {
      const runScript = () => {
        assetUrls("greensock.js", "animals/thanks/heart.png").then(([greensockUrl, animalsThanksHeartUrl]) => {
          Siepomaga.import(greensockUrl, () => {
            let startRight = 1;
            const animate = () => {
              const img = `<img class="heart" src="${animalsThanksHeartUrl}" />`;
              $("#animal-frame").append(Array(4).join(img));

              const containerW = $("#frame").width();
              const containerH = $("#frame").height();

              $.each($("#animal-frame .heart:not(.started)"), (i, el) => {
                const heart = $(el);
                heart.addClass("started");
                TweenMax.to(heart, 6.5, {
                  bezier: {
                    values: [
                      { x: (startRight * containerW) / 20, y: -containerH / 4 },
                      { x: (startRight * -containerW) / 20, y: -containerH / 2 },
                      { x: (startRight * containerW) / 20, y: (-containerH * 3) / 4 },
                      { x: (startRight * -containerW) / 20, y: -containerH },
                    ],
                  },
                  ease: Linear.easeNone,
                  force3D: false,
                  onStart() {
                    heart.fadeIn(1000);
                    Siepomaga.timeout(3000, () => {
                      heart.fadeOut(1000, () => {
                        heart.remove();
                      });
                    });
                  },
                });

                startRight *= -1;
              });
            };

            animate();

            Siepomaga.interval(4000, () => {
              if (document.hidden) { return; }
              animate();
            });
          });
        });
      };

      if ($("#frame")[0].complete) {
        runScript();
      } else {
        $("#frame").offon("load", () => {
          runScript();
        });
      }

      Siepomaga.cleanOnLoad(() => {
        $("#animal-frame .heart").remove();
      });
    }
  },

  new_common() {
    $("#show_all").offon("click", (event) => {
      event.preventDefault();
      $(".payment_methods").removeClass("hide-payments");
      $("#cart_show_all").val("1");
      $(event.currentTarget).hide();
    });

    $("#new_cart").offon("keypress", (event) => {
      if (event.which === 13) { event.preventDefault(); }
    });

    Siepomaga.common_carts.regulations();
    Siepomaga.common_carts.tabSelection();
    Siepomaga.common_carts.blik();

    if ($("#cart_channel_jp").length) {
      Siepomaga.ApplePay.available(() => {
        $("#cart_channel_jp").attr("disabled", false).parent().show();
      });
    }

    if ($("#cart_channel_ap").length) {
      Siepomaga.GooglePay.available(() => {
        $("#cart_channel_ap").attr("disabled", false).parent().show();
      });
    }
  },

  tabSelection() {
    const activateTab = (tab) => {
      $(`.payments-tab.${tab}`).addClass("current");
    };

    const scrollTo = (target, newPosition) => {
      let scrollPosition;
      let position = newPosition;
      if (position == null) { position = "top"; }
      if (!target.length) { return; }

      if (position === "top") {
        scrollPosition = target.offset().top - 60;
      } else {
        scrollPosition = (target.offset().top - window.innerHeight) + target.outerHeight() + 15;
      }

      $("html, body").stop(true);

      if (scrollPosition > window.scrollY) {
        $("html, body").animate({ scrollTop: scrollPosition }, 1000);
      }
    };

    $("input[name=\"cart[payment_group]\"]:checked").each((i, el) => {
      activateTab($(el).val());
    });

    $("input[name=\"cart[channel]\"]").offon("click", () => {
      scrollTo($("#payment-button"), "bottom");
    });

    $(".payment-methods-group").offon("click", (event) => {
      if ($(event.currentTarget).parent().find("input").is(":checked") && (window.innerWidth <= 640)) {
        scrollTo($(".payments-tab.current"));
      }
    });

    $("input[name=\"cart[payment_group]\"]").offon("change", (event) => {
      const { currentTarget } = event;

      $(".payments-tab").removeClass("current");
      if ($(currentTarget).is(":checked")) {
        activateTab($(currentTarget).val());

        if ($(".payments-tab.card.current").length) {
          if (window.innerWidth <= 640) { scrollTo($(".payments-tab.card")); }
        } else if ($(".payments-tab.transfer.current").length) {
          if (window.innerWidth <= 640) { scrollTo($(".payments-tab.transfer")); }
        } else {
          $(".blik-payment input").focus();
        }
      }

      $(".payments-tab:not(.current) input[name=\"cart[channel]\"]:checked").each((i, el) => {
        el.checked = false;
      });
    });
  },

  regulations() {
    const applyRegulationsAndStoreCard = (el) => {
      let provider = $(el).data("provider");

      if (provider === "platnosci") {
        provider = "payu";
      }

      const regulations = provider ? $("#cart_accept_regulations").data(provider) : "";

      $(".accept-regulations").find("span").html(regulations);
    };

    $("input[name=\"cart[channel]\"]").offon("change", (event) => {
      if ($(event.currentTarget).is(":checked")) { applyRegulationsAndStoreCard(event.currentTarget); }
    });

    $("input[name=\"cart[payment_group]\"]").offon("change", (event) => {
      if ($(event.currentTarget).is(":checked")) { applyRegulationsAndStoreCard(event.currentTarget); }
    });

    $("input[name=\"cart[payment_group]\"]:checked").each((i, el) => {
      applyRegulationsAndStoreCard(el);
    });

    $("input[name=\"cart[channel]\"]:checked").each((i, el) => {
      applyRegulationsAndStoreCard(el);
    });
  },

  animateCard(card) {
    TweenMax.from(card, 4, {
      scale: 0.6,
      lazy: true,
      delay: 1,
      force3D: false,
      autoAlpha: 0,
      rotation: 15,
      ease: Back.easeOut.config(4),
      onCompleteParams: ["{self}"],
      onComplete(object) {
        $(object.target).parents(".thanks-show").find(".share-button-box").fadeTo(2000, 1);
      },
    });
  },

  blik() {
    $(".blik-payment input").offon("keypress", (event) => {
      if ($(event.currentTarget).val().length >= 6) { event.preventDefault(); }
      return (event.which >= 48) && (event.which <= 57);
    });

    $(".blik-payment input").offon("keyup", (event) => {
      const currentTarget = $(event.currentTarget);

      currentTarget.val(currentTarget.val().replace(/[^\d]/, ""));
      if (currentTarget.val().length > 6) {
        currentTarget.val(currentTarget.val().substring(0, 6));
      }
    });

    $("input[name=\"cart[blik_choose]\"]").offon("change", (event) => {
      if ($(event.currentTarget).is(":checked")) {
        if (event.currentTarget.value === "code") {
          $(".blik-payment .hidden").show();
        } else {
          $(".blik-payment .hidden").hide();
        }
      }
    });

    if ($("input[name=\"cart[blik_choose]\"][value=code]").is(":checked")) { $(".blik-payment .hidden").show(); }
  },
};
