import "./cropper";
import "./select2";
import "./tinymce";
import "./tabs";

Siepomaga.RemoteModal = class RemoteModal {
  constructor() {
    const that = this;

    $(document).on("click", "a.remote-modal", (event) => {
      const { currentTarget } = event;
      event.preventDefault();

      const method = $(currentTarget).hasClass("delete") ? "delete" : "get";
      const keepAfterClose = !$(currentTarget).hasClass("dont-keep-prev-modal");
      const closable = $(currentTarget).hasClass("disable-dimmer-close");
      that.openModal(method, $(currentTarget).attr("href"), keepAfterClose, closable);
    });
  }

  openModal(method, link, newKeepModal, newClosable) {
    let keepModal = newKeepModal;
    let closable = newClosable;

    if (keepModal == null) { keepModal = true; }
    if (closable == null) { closable = true; }

    Siepomaga.startProgress();

    if (keepModal) { $(".ui.modal.active").addClass("keep-modal"); }

    $.ajax(link, {
      method,
      headers: {
        Accept: "text/html; charset=utf-8",
      },
      success(data) {
        $("#remote-modal").html(data);

        const remoteModal = $("#remote-modal .ui.modal").first();
        remoteModal.find("img").offon("load", () => {
          remoteModal.modal("refresh");
        });

        $("#remote-modal .ui.modal [data-crop][data-crop-init]").each((i, el) => {
          new Siepomaga.Cropper(el, $(el).data("crop"));
        });

        remoteModal.modal({
          closable,
          onShow() {
            new Siepomaga.Tinymce();
            new Siepomaga.Tabs(remoteModal);
            remoteModal.modal("refresh");
          },
          onVisible() {
            new Siepomaga.Select2();
            remoteModal.modal("refresh");
          },
          onHide() {
            if (!remoteModal.hasClass("keep-modal")) {
              $(".ui.modal.keep-modal").last().removeClass("keep-modal").modal("show");
            }
          },

          onHidden() {
            if (!remoteModal.hasClass("keep-modal")) {
              if ($(".ui.modal textarea.tinymce").length > 0) {
                tinymce.remove(".ui.modal textarea.tinymce");
              }

              remoteModal.remove();
            }
          },
        });

        remoteModal.attr("data-remote-modal", true);
        remoteModal.modal("show");

        const modalData = $(`<div>${data}</div>`).find(".ui.modal").data();
        if (modalData.controller && modalData.action) {
          Siepomaga.runAction(modalData.controller, modalData.action);
        }

        Siepomaga.stopProgress();
      },
      error(jqXHR, _textStatus) {
        if (jqXHR.status === 0) {
          // eslint-disable-next-line no-alert
          alert("Brak połączenia z internetem.");
        } else {
          Siepomaga.render(jqXHR.responseText);
        }
      },
    });
  }
};
