import moment from "moment";
import "daterangepicker";

Siepomaga.DateRangePicker = class DateRangePicker {
  constructor() {
    $(".date-range-picker").daterangepicker({
      showDropdowns: true,
      minDate: "2009/01/01",
      autoUpdateInput: false,
      alwaysShowCalendars: true,
      buttonClasses: "sp-button",
      applyClass: "green",
      cancelClass: "",
      linkedCalendars: false,
      locale: {
        format: "YYYY-MM-DD",
        applyLabel: "Zastosuj",
        cancelLabel: "Wyczyść",
        customRangeLabel: "Własny zakres",
        daysOfWeek: ["Niedz", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"],
        monthNames: [
          "Styczeń",
          "Luty",
          "Marzec",
          "Kwiecień",
          "Maj",
          "Czerwiec",
          "Lipiec",
          "Sierpień",
          "Wrzesień",
          "Październik",
          "Listopad",
          "Grudzień",
        ],
        firstDay: 1,
      },
      ranges: {
        "Dziś": [moment(), moment()],
        "Wczoraj": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Ostatnie 7 dni": [moment().subtract(6, "days"), moment()],
        "Ostatnie 30 dni": [moment().subtract(29, "days"), moment()],
        "Ten miesiąc": [moment().startOf("month"), moment().endOf("month")],
        "Poprzedni miesiąc": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      template: `
<div class="daterangepicker dropdown-menu daterangepicker-active">
  <div class="calendar left">
    <div class="daterangepicker_input">
      <input class="input-mini form-control" type="text" name="daterangepicker_start" value="" />
      <i class="calendar icon"></i>
      <div class="calendar-time">
        <div></div>
        <i class="wait icon"></i>
      </div>
    </div>
    <div class="calendar-table"></div>
  </div>
  <div class="calendar right">
    <div class="daterangepicker_input">
      <input class="input-mini form-control" type="text" name="daterangepicker_end" value="" />
      <i class="calendar icon"></i>
      <div class="calendar-time">
        <div></div>
        <i class="wait icon"></i>
      </div>
    </div>
    <div class="calendar-table"></div>
  </div>
  <div class="ranges">
    <div class="range_inputs">
      <button class="applyBtn" disabled="disabled" type="button"></button>
      <button class="cancelBtn" type="button"></button>
    </div>
  </div>
</div>
      `.trim(),
    });

    $(".date-range-picker").offon("apply.daterangepicker", (event, picker) => {
      const { currentTarget } = event;

      $(`#${$(currentTarget).data("from")}`).val(picker.startDate.startOf("day").format("YYYY-MM-DD HH:mm:ss"));
      $(`#${$(currentTarget).data("to")}`).val(picker.endDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"));

      $(currentTarget).html(`${picker.startDate.format("YYYY-MM-DD")} - ${picker.endDate.format("YYYY-MM-DD")}`);
    });

    $(".date-range-picker").offon("cancel.daterangepicker", (event) => {
      const { currentTarget } = event;

      $(currentTarget).html(`<span>${$(currentTarget).data("placeholder") || ""}</span>`);
      $(`#${$(currentTarget).data("from")}`).val("");
      $(`#${$(currentTarget).data("to")}`).val("");
    });

    $(".date-range-picker").each((i, el) => {
      const from = $(`#${$(el).data("from")}`);
      const to = $(`#${$(el).data("to")}`);
      if (from.val() || to.val()) {
        $(el).data("daterangepicker").setStartDate(from.val());
        $(el).data("daterangepicker").setEndDate(to.val());
        $(el).html([
          `${moment(from.val(), "YYYY-MM-DD").format("YYYY-MM-DD")}`,
          " - ",
          `${moment(to.val(), "YYYY-MM-DD").format("YYYY-MM-DD")}`,
        ].join(""));
      } else {
        $(el).html(`<span>${$(el).data("placeholder") || ""}</span>`);
      }
    });
  }
};

if ($(".date-range-picker").length && !$(".daterangepicker-active").length) {
  new Siepomaga.DateRangePicker();
}

$(document).on("turbolinks:load", () => {
  if ($(".date-range-picker").length && !$(".daterangepicker-active").length) {
    new Siepomaga.DateRangePicker();
  }
});
