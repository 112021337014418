Siepomaga.virtual_adoptions = {
  edit() {
    this.set_confirm_callback();
  },

  new() {
    this.set_confirm_callback();
  },

  // Alert on submit when user unselected all animals
  set_confirm_callback() {
    const animalsForm = $("form#virtual-animals");
    animalsForm.offon("click", "button#submit-button", (event) => {
      const selectedAnimalsCount = animalsForm.find("input[type=checkbox]:checked").length;
      if (selectedAnimalsCount !== 0) { return; }

      // eslint-disable-next-line no-alert
      if (!window.confirm("Czy na pewno chcesz zrezygnować z Wirtualnej Adopcji?")) { event.preventDefault(); }
    });
  },
};
