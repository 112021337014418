Siepomaga.monthly_supports_constant_helps = {
  new() {
    this.initializeForm();
  },

  create() {
    this.initializeForm();
  },

  edit() {
    this.initializeForm();
  },

  update() {
    this.initializeForm();
  },

  initializeForm() {
    const amountInput = $("#constant_help_amount");

    const form = new Siepomaga.MonthlySupportForm(amountInput);
    form.initialize();
  },
};
