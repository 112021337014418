Siepomaga.Cropper = class Cropper {
  constructor(el, ratio) {
    if (el.cropper) {
      el.cropper.destroy();
    }

    new window.Cropper(el, {
      viewMode: 1,
      background: true,
      aspectRatio: ratio,
      autoCropArea: 1.0,
      movable: false,
      zoomable: false,
      rotatable: true,
      checkOrientation: true,
      scalable: false,
      toggleDragModeOnDblclick: false,
      autoCrop: true,
      guides: false,
      cropBoxResizable: true,
      cropBoxMovable: true,
      checkCrossOrigin: false,
      data: $(el).data("crop-init"),

      ready: (_event) => {
        $(this).parent().next().css("display", "flex");
      },

      crop: (event) => {
        $(this).data("crop-init", event.detail);
        $(`#${event.target.dataset.photoPreview}_crop`).val(JSON.stringify(event.detail));
      },
    });
  }
};
