Siepomaga.animals_percentages = {
  index() {
    $(".c-percentages--pit_box-button").offon("click", (event) => {
      const selectedPitColumn = $(event.currentTarget).closest(".column").attr("class").split(" ")[1];
      const selectedPitBox = $(`.column.${selectedPitColumn}`).children();

      $(".pit-content").not($(`.${selectedPitColumn}`)).transition("hide");
      $(".column").not($(`.${selectedPitColumn}`)).children().removeClass("highlighted");

      $(`.pit-content.${selectedPitColumn}`).transition("scale");

      if (selectedPitBox.hasClass("highlighted")) {
        selectedPitBox.removeClass("highlighted");
      } else {
        if (window.innerWidth <= 989) {
          const selectedPitBoxTopPosition = $("#percentages-actions").offset().top - 40;
          $("html, body").animate({ scrollTop: selectedPitBoxTopPosition }, 1000);
        }

        selectedPitBox.addClass("highlighted");
      }
    });

    $(".link-to-boxes").offon("click", (_event) => {
      const pitBoxesPosition = $("#pit-boxes").offset().top;
      $("html, body").animate({ scrollTop: pitBoxesPosition }, 1000);
    });
  },

  show() {
    $("#pit-buttons .column").each((index, tab) => {
      $(tab).offon("click", () => {
        const tabId = $(this).data("tab");
        $(this).children().addClass("hover");
        $("#pit-buttons .column").not(this).children().removeClass("hover");
        $(".tab-content").not(`#${tabId}`).addClass("hidden");
        $(`#${tabId}`).removeClass("hidden");

        if (window.innerWidth <= 989) {
          $("html, body").stop(true);
          $("html, body").animate({ scrollTop: $(`#${tabId}`).offset().top - 50 }, 1000);
        }
      });
    });

    const popupSettings = {
      inline: true,
      boundary: ".zwierzakobus-map",
      preserve: true,
      on: "click",
    };

    // initializing all popups
    $(".pin").popup(popupSettings);

    $(".pin-close-button").offon("click", (event) => {
      const clickedPinContentRoot = $(event.currentTarget).closest(".popup.visible")[0];
      const clickedPin = clickedPinContentRoot.previousElementSibling;
      $(clickedPin).popup("hide");
    });

    Siepomaga.cleanOnLoad(() => {
      const clickedPinContentRoot = $(".popup.visible");
      if ((clickedPinContentRoot !== undefined) && (clickedPinContentRoot[0] !== undefined)) {
        const clickedPin = clickedPinContentRoot[0].previousElementSibling;
        if (clickedPin !== undefined) {
          $(clickedPin).popup("reposition");
        }
      }
    });
  },
};
