// This form handles:
// - showing and updating preview amount basing on amount inputs
// - anonymizing and hiding preview amount by checkboxes
// - calculating amount in different currencies when currency rates are shown on non-polish locale
// - validating amount input value
// - next charge day previewing from charge day input

Siepomaga.MonthlySupportForm = class MonthlySupportForm {
  constructor(amountInputs) {
    this.anonymousCheckbox = $("#constant_help_payment_type_anonymous");
    this.hideAmountCheckbox = $("#constant_help_payment_type_semipublic");
    this.chargeDayInput = $("#constant_help_charge_day");
    this.paymentPreviewElement = $("#payments-new .sp-payment");
    this.amountInputs = amountInputs;
    this.currentAmount = 0;
  }

  initialize() {
    this.initializeHandlers();

    this.currentAmount = Siepomaga.monthly_supports_common.sumInputsAmount(this.amountInputs);

    this.updatePaymentPreview(this.currentAmount);
    this.updateAnonymousStatus();
  }

  initializeHandlers() {
    this.handleAmountInputs();

    Siepomaga.monthly_supports_common.handleChargeDateInput(this.chargeDayInput);

    this.hideAmountCheckbox.offon("change", () => {
      // Only one checkbox can remain clicked
      if (this.hideAmountCheckbox.is(":checked") && this.anonymousCheckbox.is(":checked")) {
        this.anonymousCheckbox.prop("checked", false);
        this.anonymousCheckbox.change();
      }

      this.updatePaymentPreview(this.currentAmount);
    });

    this.anonymousCheckbox.offon("change", () => {
      this.updateAnonymousStatus();
    });

    $("#payments-new form").offon("keypress", (event) => {
      if (event.which === 13) { event.preventDefault(); }
    });
  }

  handleAmountInputs() {
    this.amountInputs.toArray().forEach((input) => {
      const el = $(input);

      el.offon("keyup", () => this.checkAndUpdateAmount(el));

      el.offon("paste", () => Siepomaga.timeout(100, () => this.checkAndUpdateAmount(el)));
    });
  }

  updateAnonymousStatus() {
    if (this.anonymousCheckbox.is(":checked")) {
      this.hideAmountCheckbox.prop("checked", false);
      this.hideAmountCheckbox.change();
    }

    this.updatePayerData();
  }

  updatePayerData() {
    let avatarURL;
    let name;
    const isWoman = this.paymentPreviewElement.data("user-sex") === "kobieta";
    const publicName = this.paymentPreviewElement.data("user-public-name");
    const isAnonymous = this.anonymousCheckbox.is(":checked");

    if (publicName && !isAnonymous) {
      name = publicName;
      avatarURL = this.paymentPreviewElement.data("user-avatar");
    } else if (isWoman) {
      name = "Anonimowa Pomagaczka";
      avatarURL = this.paymentPreviewElement.data("woman");
    } else {
      name = "Anonimowy Pomagacz";
      avatarURL = this.paymentPreviewElement.data("man");
    }

    this.paymentPreviewElement.find(".sp-payment-name").html(name);
    this.paymentPreviewElement.find("img").attr("src", avatarURL);
  }

  updatePaymentPreview(newAmount) {
    const previewAmount = this.hideAmountCheckbox.is(":checked") ? "X" : Siepomaga.formatAmount(newAmount);

    const previewAmountElement = this.paymentPreviewElement.find(".sp-payment-amount strong span");
    previewAmountElement.html(`${previewAmount} zł`);

    this.recalculateCurrencies(newAmount);
  }

  recalculateCurrencies(newAmount) {
    const amount = newAmount.toString();
    const parsedAmount = amount.replace(",", ".");

    $("#new_constant_help .currencies div, [id^=edit_constant_help_] .currencies div").each((i, el) => {
      const rate = $(el).data("rate");

      const convertedAmount = parsedAmount > 0 ? Siepomaga.formatAmount(parsedAmount / rate) : 0;

      $("span", el).html(convertedAmount);
    });
  }

  checkAndUpdateAmount(input) {
    Siepomaga.monthly_supports_common.toggleAmountTooltips($(input));

    // Bring back previous valid value if new value is invalid
    // To get amount for only one input,
    // we have to substract other inputs amount from cached value which contains all inputs amount
    if (!Siepomaga.monthly_supports_common.isAmountValid(input.val())) {
      const otherAmountInputs = this.amountInputs.not($(input));
      const sumFromOtherInputs = Siepomaga.monthly_supports_common.sumInputsAmount(otherAmountInputs);
      const thisInputValue = this.currentAmount - sumFromOtherInputs;

      input.val(thisInputValue);

      return;
    }

    this.currentAmount = Siepomaga.monthly_supports_common.sumInputsAmount(this.amountInputs);

    this.updatePaymentPreview(this.currentAmount);
  }
};
