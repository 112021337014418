import { railsEnv } from "railsVariables";

Siepomaga.OneSignal = class OneSignal {
  constructor() {
    if (railsEnv !== "production") { return; }

    const appId = $("meta[property='onesignal:appId']").attr("content");

    if (appId === undefined) { return; }

    $.getScript("https://cdn.onesignal.com/sdks/OneSignalSDK.js", () => {
      const oneSignal = window.OneSignal || [];
      oneSignal.push(["init", {
        appId,
        safari_web_id: $("meta[property='onesignal:safari_web_id']").attr("content"),
        autoRegister: true,
        notifyButton: { enable: false },
        welcomeNotification: { disable: true },
      }]);
    });
  }
};
