$(document).on("turbolinks:load", () => {
  if (!Siepomaga.onceFired) {
    $("form").each((i, el) => el.reset()); // Fix Firefox input cache
    Siepomaga.commonOnce();
    Siepomaga.onceFired = true;
  }

  Siepomaga.common();

  const controllerName = $("body").data("controller");
  const actionName = $("body").data("action");
  Siepomaga.runAction(controllerName, actionName);

  if (window.location.hash) { window.scrollTo(0, 0); }
});

document.addEventListener("turbolinks:before-cache", () => {
  $("script[nonce]").each((index, element) => {
    $(element).attr("nonce", element.nonce);
    const sliders = document.querySelectorAll(".slick-initialized");
    sliders.forEach((slider) => {
      if (slider.slick.$slides !== null) { $(slider).slick("unslick"); }
    });
  });
});

document.addEventListener("turbolinks:request-start", (event) => {
  const {
    xhr,
  } = event.data;
  xhr.setRequestHeader("X-Turbolinks-Nonce", $("meta[name='csp-nonce']").prop("content"));
});

window.handlePayuCvvResponse = (_params) => {
  Turbolinks.visit("/platnosc/success");
};
