import "./popup";

Siepomaga.Favorites = class Favorites {
  constructor() {
    $(document).on("click", "a.favorites-star.add, a.favorites-star.remove", (event) => {
      event.preventDefault();

      const $el = $(event.currentTarget);

      $.ajax({
        url: event.currentTarget.href,
        method: $el.hasClass("add") ? "POST" : "DELETE",
        success(json) {
          $(".favorites-link .label").html(json.count);

          if (json.count > 0) {
            $(".favorites-link").removeClass("hidden");
          } else {
            $(".favorites-link").addClass("hidden");
          }

          if ($el.hasClass("remove") && ($("body").data("controller") === "common_cause_favorites")) {
            const column = $el.parent(".sp-cause").parent(".column");
            column.fadeOut("slow", () => {
              column.remove();
              if (json.count === 0) {
                $(".favorites-index .bordered-box.info").removeClass("hidden");
              }
            });
          } else {
            $el.replaceWith(json.html);
            new Siepomaga.Popup();
          }
        },
      });
    });

    $(document).on("click", "a.favorites-star.open-modal[data-favorite-cause-id]", (event) => {
      const id = $(event.currentTarget).data("favorite-cause-id");
      const date = new Date();
      date.setTime(date.getTime() + (5 * 60 * 1000)); // 5 minutes
      $.cookie("favorite-cause-id", id, { expires: date, path: "/" });
    });
  }
};
