window.Siepomaga = {
  currentLayoutInitOnce() {},
  currentLayoutInit() {},

  waitUntil(condition, callback) {
    if (condition()) {
      callback();
    } else {
      Siepomaga.timeout(100, () => {
        Siepomaga.waitUntil(condition, callback);
      });
    }
  },

  import(url, callback) {
    if (!this.importedFiles) { this.importedFiles = []; }

    if (this.importedFiles.indexOf(url) >= 0) {
      callback();
    } else {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.onload = () => {
        this.importedFiles.push(url);
        callback();
        $(script).remove();
      };
      document.head.appendChild(script);
    }
  },

  getScript(url, callback) {
    if (!url) { return; }

    $.ajax({
      method: "GET",
      headers: {
        // eslint-disable-next-line max-len
        Accept: "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01",
      },
      converters: {
        "text nonceScript": (result) => result,
      },
      dataType: "nonceScript",
      contents: {
        nonceScript: /\b(?:java|ecma)script\b/,
      },
      url,
      success(response) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.text = response;
        script.setAttribute("nonce", Rails.cspNonce());

        document.head.appendChild(script);
        setTimeout((() => {
          callback();
          $(script).remove();
        }), 10);
      },
    });
  },

  runAction(controllerName, actionName) {
    if (Siepomaga[controllerName] && Siepomaga[controllerName].common) {
      Siepomaga[controllerName].common();
    }

    if (Siepomaga[controllerName] && Siepomaga[controllerName][actionName]) {
      Siepomaga[controllerName][actionName]();
    }
  },

  interval(time, callback) {
    const interval = setInterval(callback, time);
    const clear = () => {
      clearInterval(interval);
      $(document).off("turbolinks:before-cache", clear);
    };
    $(document).on("turbolinks:before-cache", clear);
  },

  timeout(time, callback) {
    const timeout = setTimeout(callback, time);
    const clear = () => {
      clearTimeout(timeout);
      $(document).off("turbolinks:before-cache", clear);
    };
    $(document).on("turbolinks:before-cache", clear);
    return timeout;
  },

  onlyDesktop(params) {
    let enabled = window.innerWidth >= 992;
    if (enabled) { params.create(); }

    Siepomaga.cleanOnLoad(() => {
      if (enabled && params.destroy) { params.destroy(); }
    });

    $(window).resize(() => {
      if (!enabled && (window.innerWidth >= 992)) {
        enabled = true;
        params.create();
      } else if (enabled && (window.innerWidth < 992)) {
        enabled = false;
        Siepomaga.timeout(10, () => {
          if (params.destroy) { params.destroy(); }
        });
      }
    });

    if (params.once) { params.once(); }
  },

  onlyTablet(params) {
    let enabled = window.innerWidth < 992;
    if (enabled) { params.create(); }

    Siepomaga.cleanOnLoad(() => {
      if (enabled) { params.destroy(); }
    });

    $(window).resize(() => {
      if (!enabled && (window.innerWidth < 992)) {
        enabled = true;
        params.create();
      } else if (enabled && (window.innerWidth >= 992)) {
        enabled = false;
        Siepomaga.timeout(10, () => {
          params.destroy();
        });
      }
    });
  },

  onlyMobile(params) {
    let enabled = window.innerWidth < 768;
    if (enabled) { params.create(); }

    Siepomaga.cleanOnLoad(() => {
      if (enabled) { params.destroy(); }
    });

    $(window).resize(() => {
      if (!enabled && (window.innerWidth < 768)) {
        enabled = true;
        params.create();
      } else if (enabled && (window.innerWidth >= 768)) {
        enabled = false;
        Siepomaga.timeout(10, () => {
          params.destroy();
        });
      }
    });
  },

  cleanOnLoad(callback) {
    const clear = () => {
      callback();
      $(document).off("turbolinks:before-cache", clear);
    };
    $(document).on("turbolinks:before-cache", clear);
  },

  formatAmount(amount) {
    amountString = amount.toString().replace(",", ".");
    if (parseInt(amountString, 10) !== parseFloat(amountString)) {
      return parseFloat(amountString).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+,)/g, "$1 ");
    }
    return parseInt(amountString, 10).toString().replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
  },

  render(html) {
    document.open();
    document.write(html);
    document.close();
  },

  startProgress() {
    if (!Turbolinks.supported) { return; }
    Turbolinks.controller.adapter.progressBar.setValue(0);
    Turbolinks.controller.adapter.progressBar.show();
  },

  stopProgress() {
    if (!Turbolinks.supported) { return; }
    Turbolinks.controller.adapter.progressBar.hide();
    Turbolinks.controller.adapter.progressBar.setValue(100);
  },

  replaceBody(data) {
    const doc = document.implementation.createHTMLDocument("response");
    doc.documentElement.innerHTML = data;
    Turbolinks.dispatch("turbolinks:before-cache");
    document.documentElement.replaceChild(doc.body, document.body);
    Turbolinks.dispatch("turbolinks:load");
  },

  sanitizeUserInput(string) {
    const el = document.createElement("div");
    el.innerHTML = string;
    return $(el).text();
  },
};
