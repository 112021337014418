import "./count_down";

Siepomaga.Livesearch = class Livesearch {
  constructor() {
    this.bindButton();
    this.bindQueryInput();

    this.queryCache = "";
    this.timeout = null;
    this.ajax = null;
    this.active = false;
    this.slick = null;
  }

  bindButton() {
    $("#livesearch-button").offon("click", (event) => {
      event.preventDefault();
      if (this.active) {
        this.hideLiveSearch();
      } else {
        this.showLiveSearch();
      }
    });
  }

  bindQueryInput() {
    $("#livesearch-query").offon("keyup", (event) => {
      const value = $("#livesearch-query").val();
      const keycode = event.keyCode || event.which;

      if (keycode === 27) {
        if (value === "") {
          this.hideLiveSearch();
        } else {
          $("#livesearch-query").val("");
        }
      } else if (value !== this.queryCache) {
        this.queryCache = value;
        this.getResults();
      }
    });
  }

  getResults() {
    const value = $("#livesearch-query").val();
    if (!(value.length >= 3)) { return; }

    this.showSpinner();
    if (this.ajax) { this.ajax.abort(); }
    clearTimeout(this.timeout);

    this.timeout = Siepomaga.timeout(300, () => {
      this.ajax = $.ajax({
        url: "/szukaj_live",
        type: "get",
        dataType: "html",
        data: {
          query: value,
        },

        success: (data) => {
          $("#most-searched-causes").hide();
          $("#livesearch .search_results").html(data);

          if (this.slick) {
            this.slick.slick("unslick");
            this.slick = null;
          }

          this.slick = $("#livesearch .livesearch-slick").slick({
            dots: false,
            infinite: false,
            nextArrow: $("#livesearch i.right"),
            prevArrow: $("#livesearch i.left"),
          });

          // eslint-disable-next-line max-len
          $(".search_results .cause-card, .search_results .foundation-card, .search_results .sp-company").offon("click", (event) => {
            $.ajax({
              url: "count_search",
              type: "put",
              data: {
                object: {
                  id: $(event.currentTarget).data("id"),
                  type: $(event.currentTarget).data("type"),
                },
              },
            });
          });

          new Siepomaga.CountDown();

          Siepomaga.cleanOnLoad(() => {
            this.slick.slick("unslick");
          });
        },

        complete: () => {
          this.hideSpinner();
        },
      });
    });
  }

  hideSpinner() {
    $("#livesearch i.loading.icon").hide();
  }

  showSpinner() {
    $("#livesearch i.loading.icon").show();
  }

  showLiveSearch() {
    $.getJSON("/most_searched_causes", (response) => {
      if (response.html) {
        $("#most-searched-causes").html(response.html);
      }

      $("#livesearch").slideDown();
      $("#livesearch-query").focus();
    });
    this.active = true;
  }

  hideLiveSearch() {
    $("#livesearch").slideUp();
    this.active = false;
  }
};
