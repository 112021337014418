Siepomaga.common_payments = {
  edit() {
    $("#payment_hide_amount").offon("change", () => {
      if ($("#payment_hide_amount:checked").length && $("#payment_anonymous:checked").length) {
        $("#payment_anonymous").prop("checked", false);
        $("#payment_anonymous").change();
      }
    });

    let nameCache = $("#payment_signature").val();

    const updateAnonymous = () => {
      if ($("#payment_anonymous:checked").length) { nameCache = $("#payment_signature").val(); }
      if ($("#payment_anonymous:checked").length) {
        $("#payment_hide_amount").prop("checked", false);
        $("#payment_hide_amount").change();
        if ($(".signature").hasClass("field_with_errors")) {
          const errorDiv = $(".signature").next(".error");
          errorDiv.slideUp(() => errorDiv.remove());
        }
      }

      if ($("#payment_anonymous:checked").length) {
        // eslint-disable-next-line max-len
        const woman = ($("#new_payment .sp-payment").data("user-sex") === "kobieta") || $("#payment_sex_k:checked").length || $("#payments-new[data-user-sex=\"kobieta\"]").length;

        if (woman) {
          $("#payment_signature").val("Anonimowa Pomagaczka");
        } else {
          $("#payment_signature").val("Anonimowy Pomagacz");
        }
        $("#payment_signature").attr("disabled", "disabled");
        $("#new_payment .sp-payment-time").show();
      } else {
        $("#payment_signature").val(nameCache);
        $("#payment_signature").attr("disabled", false);
      }
    };

    updateAnonymous();

    $("#payment_anonymous").offon("change", updateAnonymous);
  },

  update() {
    this.edit();

    // Form errors hiding after click
    $("form input").offon("focus click", (event) => {
      if ($(event.currentTarget).parent().hasClass("field_with_errors")) {
        const errorDiv = $(event.currentTarget).parents().next(".error");

        errorDiv.slideUp(() => $(errorDiv).remove());
      }
    });
  },
};
