import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { fingerprintjsDomainSetting, fingerprintjsTokenSetting } from "railsVariables";

const fingerprintFeatureFlag = document.querySelector("meta[name='fingerprint-feature-flag']")?.content;

if (fingerprintFeatureFlag === "true" && !window.location.pathname.startsWith("/panel")) {
  const fpPromise = FingerprintJS.load({
    apiKey: fingerprintjsTokenSetting,
    endpoint: [
      fingerprintjsDomainSetting,
      FingerprintJS.defaultEndpoint,
    ],
    scriptUrlPattern: [
      `${fingerprintjsDomainSetting}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
      FingerprintJS.defaultScriptUrlPattern,
    ],
    region: "eu",
  });

  window.fpPromise = fpPromise;
}
