Siepomaga.animals_home = {
  index() {
    this.slideshow();
    this.updateStats();
    this.fixFooterOnIE();
  },

  slideshow() {
    $("#dots-before-js").hide();
    const slickSlideshow = $("#homepage-slideshow").slick({
      arrows: false,
      dots: true,
      centerMode: true,
      variableWidth: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 1000,
      pauseOnDotsHover: true,
      slidesToShow: 1,
    });

    $("#homepage-slideshow").offon("click", ".slick-slide[aria-hidden=true]", (event) => {
      event.preventDefault();
      const index = $(event.target).closest(".slick-slide").data("slick-index");
      $("#homepage-slideshow").slick("slickGoTo", index);
    });

    Siepomaga.cleanOnLoad(() => {
      slickSlideshow.slick("unslick");
    });
  },

  updateStats() {
    Siepomaga.interval(5000, () => {
      let ajax = null;

      if (document.hidden) { return; }

      ajax = $.get({
        url: "/statystyki",
        timeout: 3000,
        success(data) {
          if (parseFloat(data.total_amount) <= parseFloat($("#total_amount").data("value"))) { return; }

          new Siepomaga.CountUp("#total_amount", data.total_amount, " zł");
          new Siepomaga.CountUp("#total_causes", data.total_causes);
        },
      });

      Siepomaga.cleanOnLoad(() => {
        if (ajax) { ajax.abort(); }
      });
    });
  },

  fixFooterOnIE() {
    const msie = document.documentMode;
    const shouldFix = msie && (msie <= 11);

    if (!shouldFix) { return; }

    $(".bg-icon").css("visibility", "hidden");
    $(".footer-socialmedia-icons").css("position", "static");
  },
};
