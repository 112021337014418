Siepomaga.EventTracking = class EventTracking {
  constructor() {
    $(document).on("click", "a[data-ga]", (event) => {
      if (typeof (window.ga) === "function") {
        const analyticsEvent = $(event.currentTarget).data("ga");
        window.ga("send", "event", analyticsEvent[0], analyticsEvent[1], analyticsEvent[2]);
      }

      return true;
    });
  }
};
