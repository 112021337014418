import { railsEnv } from "railsVariables";

Siepomaga.GooglePay = {
  initialized: false,

  init() {
    this.initialized = true;

    this.env = railsEnv === "production" ? "PRODUCTION" : "TEST";
    this.baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
    };
    this.tokenizationSpecification = {
      type: "PAYMENT_GATEWAY",
      parameters: {
        gateway: "payu",
        gatewayMerchantId: $("#payu-pos-id").data("pos-id").toString(),
      },
    };
    this.allowedAuthMethods = ["CRYPTOGRAM_3DS", "PAN_ONLY"];
    this.allowedCardNetworks = ["MASTERCARD", "VISA"];
    this.baseCardPaymentMethod = {
      type: "CARD",
      parameters: {
        allowedAuthMethods: this.allowedAuthMethods,
        allowedCardNetworks: this.allowedCardNetworks,
      },
    };
    this.cardPaymentMethod = $.extend(
      true,
      {},
      { tokenizationSpecification: this.tokenizationSpecification },
      this.baseCardPaymentMethod,
    );
  },

  available(callback) {
    if (this.isUnsupported()) { return; }
    if (!this.initialized) { this.init(); }

    Siepomaga.import("https://pay.google.com/gp/p/js/pay.js", () => {
      const paymentsClient = new google.payments.api.PaymentsClient({ environment: this.env });

      const isReadyToPayRequest = $.extend(true, {}, this.baseRequest);
      isReadyToPayRequest.allowedPaymentMethods = [this.baseCardPaymentMethod];

      paymentsClient.isReadyToPay(isReadyToPayRequest).then((response) => {
        callback(response);
      });
    });
  },

  showButton(amount) {
    Siepomaga.import("https://pay.google.com/gp/p/js/pay.js", () => {
      const paymentsClient = new google.payments.api.PaymentsClient({ environment: this.env });

      let paymentDataRequest = $.extend(true, {}, this.baseRequest);
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: "NOT_CURRENTLY_KNOWN",
        currencyCode: "PLN",
        countryCode: "PL",
      };

      paymentsClient.prefetchPaymentData(paymentDataRequest);

      const button = paymentsClient.createButton({
        buttonColor: "black",
        onClick: () => {
          $("#googlepay-container .error").hide();

          paymentDataRequest = $.extend(true, {}, this.baseRequest);
          paymentDataRequest.allowedPaymentMethods = [this.cardPaymentMethod];
          paymentDataRequest.transactionInfo = {
            totalPriceStatus: "FINAL",
            totalPrice: amount.toString(),
            currencyCode: "PLN",
            countryCode: "PL",
          };
          paymentDataRequest.merchantInfo = {
            merchantName: "ratujemyzwierzaki.pl",
            merchantId: "06273285503477247760",
          };

          paymentsClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
            $.ajax({
              method: "POST",
              url: "",
              dataType: "json",
              data: {
                authorizationCode: btoa(paymentData.paymentMethodData.tokenizationData.token),
              },
              success(response) {
                if (response.success) {
                  Turbolinks.visit(response.url);
                } else {
                  $("#googlepay-container .error").show();
                }
              },
            });
          }).catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          });
        },
      });

      $("#googlepay-container .button-container").html(button);
    });
  },
  isUnsupported() {
    const ua = navigator.userAgent;

    if (ua.indexOf("Trident/") > 0) { // Internet Explorer 11
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10) === 11;
    }

    // eslint-disable-next-line max-len
    const isUnsupportedBrowser = (ua.indexOf("FBAN") >= 0) || (ua.indexOf("FBAV") >= 0) || (ua.indexOf("Instagram") >= 0) || (ua.indexOf("SamsungBrowser") >= 0);
    return isUnsupportedBrowser;
  },
};
