Siepomaga.SocialmediaButtons = class SocialmediaButtons {
  constructor() {
    this.bindButtons();
  }

  bindButtons() {
    $("[data-fb-share]").offon("click", (event) => {
      event.preventDefault();

      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent($(event.currentTarget).data("fb-share"))}`,
        "shareWindow",
        "left=20,top=20,width=600,height=600",
      );
    });

    $("[data-twitter-tweet]").offon("click", (event) => {
      const { currentTarget } = event;
      event.preventDefault();

      let url = "http://twitter.com/share?";
      url += `url=${encodeURIComponent($(currentTarget).data("twitter-tweet"))}`;
      url += "&related=RatujemyZwierzaki.pl";
      url += `&text=${encodeURIComponent($(currentTarget).data("twitter-text"))}`;
      url += `&via=${encodeURIComponent($(currentTarget).data("twitter-via"))}`;

      const width = 550;
      const height = 420;
      const left = ($(window).width() - width) / 2;
      const top = ($(window).height() - height) / 2;
      const opts = `status=1,width=${width},height=${height},top=${top},left=${left}`;
      window.open(url, "twitter", opts);
    });

    $("[data-fb-message-share]").offon("click", (event) => {
      event.preventDefault();

      if (typeof FB !== "undefined" && FB !== null) {
        FB.ui({
          method: "send",
          link: $(event.currentTarget).data("fb-message-share"),
          display: "popup",
        });
      }
    });
  }
};
