Siepomaga.common_fundraises = {
  show() {
    const url = new URL(window.location.href);
    if ((url.searchParams.has("sharer_modal")) && $("#share-methods-modal").length) {
      $("#share-methods-modal").modal("show");
    }

    $("iframe[data-src]").visibility({
      onTopVisible() {
        $(this).attr("src", $(this).data("src"));
      },
    });

    Siepomaga.causes.refreshPayments();

    Siepomaga.onlyDesktop({
      create() {
        $("#right-column .ui.sticky").sticky({
          offset: 20,
          context: "#left-column",
          observeChanges: "#left-column",
        });

        $("#left-column img").on("load", () => {
          $("#right-column .ui.sticky").sticky("refresh");
        });
      },
      destroy() {
        $("#right-column .ui.sticky").sticky("destroy");
      },
    });

    if ($("#fundraises-show").data("url")) {
      let prevState = {};
      Siepomaga.interval(5000, () => {
        let ajax = null;
        if (document.hidden) { return; }
        if (ajax) { ajax.abort(); }

        ajax = $.get({
          url: $("#fundraises-show").data("url"),
          success(data) {
            if (parseFloat(data.amount) <= parseFloat($("#right-column .amount").data("value"))) { return; }

            new Siepomaga.CountUp("#right-column .payments-count", data.payments_count);
            new Siepomaga.CountUp("#right-column .amount", data.amount, " zł");
            const currentPercentageValue = $("#right-column .progressbar .percentage").data("value");
            if (data.percentage !== currentPercentageValue) {
              $("#right-column .progressbar .bar").animate({ width: `${data.percentage}%` }, 3000);
              if ((currentPercentageValue < 100) && (data.percentage >= 100) && !$(".black-white").length) {
                $("#right-column .progressbar .sp-progressbar").addClass("success-new");
              }
            }
            if ($("#right-column .progressbar .percentage").length) {
              new Siepomaga.CountUp(".progressbar .percentage", data.percentage, "%");
            }
            if ($("#right-column .amount-left").length) {
              new Siepomaga.CountUp("#right-column .amount-left", data.amount_left, " zł", (() => {
                if ($("#right-column .still-needed").length && (data.amount_left === "0.0")) {
                  $("#right-column .still-needed").fadeOut("slow");
                  $("#right-column .text").addClass("success");
                }
              }));
            }

            if (data.payments_count > prevState.payments_count) {
              const newPaymentsCount = data.payments_count - prevState.payments_count;
              const paymentsLink = $("#show-new-payments");
              paymentsLink.attr(
                "data-new-payments",
                parseInt(paymentsLink.attr("data-new-payments"), 10) + newPaymentsCount,
              );
              $("#show-new-payments > span").html(`(${paymentsLink.attr("data-new-payments")})`);
              if ($(".new-payments-info").is(":hidden")) { $(".new-payments-info").transition("fade down"); }
            }

            prevState = data;
          },
        });

        Siepomaga.cleanOnLoad(() => {
          if (ajax) { ajax.abort(); }
        });
      });
    }

    const handleScroll = () => {
      const button = $("#right-column .big.sp-button");
      if (!$("#navigation .mobile-menu:visible").length || !button.length) { return; }

      if ((window.scrollY + window.innerHeight) >= $(document).height()) {
        $(".mobile-donation-buttons").removeClass("active");
      } else if (window.scrollY > ((button.offset().top + button.outerHeight()) - $("#navigation").outerHeight())) {
        $(".mobile-donation-buttons").addClass("active");
      } else {
        $(".mobile-donation-buttons").removeClass("active");
      }
    };

    handleScroll();

    Siepomaga.onlyTablet({
      create() {
        $(window).on("scroll", handleScroll);
      },
      destroy() {
        $(window).off("scroll", handleScroll);
      },
    });
  },

  new() {
    if ($(".fundraise_ends_at").hasClass("field_with_errors")) {
      $(".fundraise_ends_at").removeClass("hidden");
    }

    if ($(".fundraise_ends_at").is(":hidden")) {
      $("label[for=\"fundraise_no_end_date\"]").show();
    } else {
      $("label[for=\"fundraise_no_end_date\"]").hide();
    }

    $("#fundraise_no_end_date").offon("change", () => {
      if ($("#fundraise_no_end_date:checked").length) {
        $(".fundraise_ends_at").addClass("hidden");
        $("label[for=\"fundraise_no_end_date\"]").show();
      } else {
        $(".fundraise_ends_at").removeClass("hidden");
        $("label[for=\"fundraise_no_end_date\"]").hide();
      }
    });
  },

  create() {
    this.new();
  },

  edit() {
    this.new();
  },

  update() {
    this.new();
  },
};
