Siepomaga.certificates = {
  new() {
    $("#certificate-email-submit").prop("disabled", true);
    $("#email-field").on("input", () => {
      $("form#email-form .error").slideUp();
      $("#email-field").css("border-color", "green");
    });

    $(".g-recaptcha").attr("data-callback", "recaptchaSuccessfuly");
    $(".g-recaptcha").attr("data-expired-callback", "recaptchaExpire");
  },
};

window.recaptchaSuccessfuly = () => {
  $("#certificate-email-submit").prop("disabled", false);
};

window.recaptchaExpire = () => {
  $("#certificate-email-submit").prop("disabled", true);
};
