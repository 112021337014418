Siepomaga.SecurePassword = class SecurePassword {
  constructor() {
    this.testPassword();
    $(".password-information .show-password").offon("click", () => {
      $(".password-information .show-password .icon").toggleClass("slash");
      if ($(".wrapper.secure_password input").prop("type") === "password") {
        $(".wrapper.secure_password input").prop("type", "text");
      } else {
        $(".wrapper.secure_password input").prop("type", "password");
      }
    });
    $(".wrapper.secure_password input").offon("keyup", this.testPassword);
    $(".wrapper.secure_password input").offon("paste", () => {
      Siepomaga.timeout(100, () => {
        this.testPassword();
      });
    });
    $(".wrapper.secure_password input, .password-information .show-password").offon("focus click", () => {
      // eslint-disable-next-line max-len
      $(".password-information .chars, .password-information .digit, .password-information .capital-letter").removeClass("invalid");
    });
    $(".password-information .show-password").offon("click", () => {
      $(".wrapper.secure_password.field_with_errors").removeClass("field_with_errors");
      $(".wrapper.secure_password .error").remove();
    });
  }

  testPassword() {
    const value = $(".wrapper.secure_password input").val();

    if (value.length >= 8) {
      $(".password-information .chars").addClass("valid");
    } else {
      $(".password-information .chars").removeClass("valid");
      $(".wrapper.secure_password.field_with_errors input ~ .password-information .chars").addClass("invalid");
    }

    if ((/\d/).test(value)) {
      $(".password-information .digit").addClass("valid");
    } else {
      $(".password-information .digit").removeClass("valid");
      $(".wrapper.secure_password.field_with_errors input ~ .password-information .digit").addClass("invalid");
    }

    if ((/[A-ZĄĆĘŁŃÓŚŻŹ]/).test(value)) {
      $(".password-information .capital-letter").addClass("valid");
    } else {
      $(".password-information .capital-letter").removeClass("valid");
      $(".wrapper.secure_password.field_with_errors input ~ .password-information .capital-letter").addClass("invalid");
    }
  }
};
