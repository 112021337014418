Siepomaga.Slideout = class Slideout {
  constructor() {
    if (!$("#mobile-menu").length) { return; }

    const slideout = new window.Slideout({
      panel: document.getElementById("page"),
      menu: document.getElementById("mobile-menu"),
      padding: 256,
      tolerance: 70,
      touch: false,
    });

    $("#mobile-menu-button").click((event) => {
      event.preventDefault();
      slideout.toggle();
    });

    slideout.once("open", slideout._initTouchEvents); // eslint-disable-line no-underscore-dangle
    slideout.on("open", slideout.enableTouch);
    slideout.on("close", () => {
      slideout.disableTouch();
    });

    const close = (event) => {
      event.preventDefault();
      event.stopPropagation();
      slideout.close();
    };

    slideout
      .on("beforeopen", function handleBeforeOpen() {
        $("#mobile-menu-button").addClass("opened");
        $("#navigation, .mobile-donation-buttons, .mobile-fixed").css("transition", "transform 300ms ease");
        $("#navigation, .mobile-donation-buttons, .mobile-fixed").css("transform", "translateX(256px)");
        $(".slideout-panel").on("scroll touchmove mousewheel", (event) => event.preventDefault());
        this.panel.classList.add("panel-open");
      }).on("open", function handleOpen() {
        $("#navigation").css("transition", "");
        $(".mobile-donation-buttons, .mobile-fixed").css("transition", "");
        this.panel.addEventListener("click", close, true);
        $("#navigation, .mobile-donation-buttons, .mobile-fixed").each((i, el) => {
          el.addEventListener("click", close, true);
        });
      }).on("beforeclose", function handleBeforeClose() {
        $("#navigation, .mobile-donation-buttons, .mobile-fixed").css("transition", "transform 300ms ease");
        $("#navigation, .mobile-donation-buttons, .mobile-fixed").css("transform", "translateX(0px)");
        $("#mobile-menu-button").removeClass("opened");
        $(".slideout-panel").off("scroll touchmove mousewheel");
        this.panel.classList.remove("panel-open");
        this.panel.removeEventListener("click", close, true);
        $("#navigation, .mobile-donation-buttons, .mobile-fixed").each((i, el) => {
          el.removeEventListener("click", close, true);
        });
      }).on("close", () => {
        $("#navigation").css("transition", "");
        $(".mobile-donation-buttons").css("transition", "");
      })
      .on("translatestart", () => {
        $(".mobile-donation-buttons").addClass("disable-default-transition");
      })
      .on("translateend", () => {
        $(".mobile-donation-buttons").removeClass("disable-default-transition");
      })
      .on("translate", (translated) => {
        $(".mobile-donation-buttons").css("transition-duration", "");
        $("#navigation, .mobile-donation-buttons, .mobile-fixed").css("transform", `translateX(${translated}px)`);
      });

    Siepomaga.cleanOnLoad(() => {
      slideout.destroy();
    });
  }
};
