import { assetUrls } from "webpackHelpers";
import { newsletterTimerUrlSetting, censurableEditorsSetting } from "railsVariables";

import "tinymce/skins/lightgray/content.min.css";
import "tinymce/skins/lightgray/skin.min.css";

import tinymce from "tinymce/tinymce";
import "tinymce/themes/modern/theme";
import "tinymce/plugins/link/plugin";
import "tinymce/plugins/fullscreen/plugin";
import "tinymce/plugins/paste/plugin";
import "tinymce/plugins/code/plugin";
import "tinymce/plugins/autolink/plugin";
import "tinymce/plugins/noneditable/plugin";
import "tinymce/plugins/media/plugin";
import "tinymce/plugins/textcolor/plugin";
import "tinymce/plugins/lists/plugin";
import "tinymce/plugins/image/plugin";

Siepomaga.Tinymce = class Tinymce {
  constructor() {
    if ($("textarea.tinymce").length > 0) {
      assetUrls(
        "tinymce_pl.js",
        "tinymce.css",
        "tinymce_newsletter.css",
      ).then(([tinymcePLUrl, tinymceCssUrl, tinymceNewsletterCssUrl]) => {
        tinymce.init({
          skin: false,
          selector: "textarea.tinymce",
          menubar: false,
          height: 400,
          plugins: "link fullscreen paste code autolink noneditable media textcolor lists image",
          paste_as_text: true,
          default_link_target: "_blank",
          target_list: false,
          link_assume_external_targets: false,
          content_css: tinymceCssUrl,
          image_dimensions: true,
          elementpath: false,
          resize: true,
          statusbar: true,
          relative_urls: false,
          object_resizing: false,
          remove_script_host: false,
          convert_urls: false,
          extended_valid_elements: "i[class]",
          noneditable_regexp: /\[\[(.*?)\]\]/g,
          wordcount_countregex: /[a]+/,
          branding: false,
          media_alt_source: false,
          media_poster: false,
          media_dimensions: false,
          media_scripts: false,
          keep_styles: false,
          theme_advanced_blockformats: "p,div",
          language_url: tinymcePLUrl,
          style_formats: [{ title: "Header", block: "h2" }],
          textcolor_map: [
            "000000", "Czarny",
            "7eae2a", "Zielony",
            "1f99d6", "Niebieski",
            "757575", "Szary",
            "ba0000", "Czerwony",
          ],
          init_instance_callback(editor) {
            if ($(editor.getElement()).hasClass("with-counter")) {
              const getCharacterLength = () => editor.getContent({ format: "text" })
                .trim()
                .replace(/(\n)+/g, " ")
                .length;

              editor.theme.panel.find("#statusbar")[0].insert({
                type: "label",
                name: "charcount",
                text: ["{0} znaków", getCharacterLength()],
                classes: "wordcount",
                disabled: editor.settings.readonly,
              }, 0);

              editor.on("keyup setcontent beforeaddundo", () => {
                editor.theme.panel.find("#charcount").text(["{0} znaków", getCharacterLength()]);
              });
            }

            const wrapper = $(editor.editorContainer).parents(".wrapper");
            if (wrapper.hasClass("field_with_errors")) {
              editor.on("click focus", () => {
                const errorDiv = wrapper.find(".error");
                errorDiv.slideUp(() => errorDiv.remove());
                wrapper.removeClass("field_with_errors");
              });
            }

            editor.on("NodeChange", (_event) => {
              const node = $(tinyMCE.activeEditor.selection.getNode());
              if (node.is("p") && node.hasClass("censored-img") && !(node.has("img").length >= 1)) {
                node.remove();
              }
            });
          },

          setup(editor) {
            const toolbar = ["bold italic underline"];

            if ($(editor.getElement()).attr("id") === "newsletter_campaign_body") {
              toolbar.unshift("styleselect");
            }

            if ($(editor.getElement()).hasClass("with-font")) {
              toolbar.push("forecolor");
            }

            toolbar.push("link");

            if ($(editor.getElement()).hasClass("with-add-photo")) {
              editor.addButton("insertimage", {
                icon: "image",
                text: "Dodaj zdjęcie",
                onClick() {
                  Siepomaga.RemoteModal.prototype.openModal(
                    "get",
                    // eslint-disable-next-line max-len
                    `/attached_images/new?editor_id=${editor.id}&token=${$("input[name$='[token]']", editor.formElement).val() || ""}`,
                  );
                },
              });
              toolbar.push("insertimage");
            }

            if ($(editor.getElement()).hasClass("with-add-video")) {
              editor.addButton("insertvideo", {
                icon: "media",
                text: "Dodaj film",
                onClick() {
                  Siepomaga.RemoteModal.prototype.openModal("get", `/filmy/nowy?editor_id=${editor.id}`);
                },
              });
              toolbar.push("insertvideo");
            }

            if ($(editor.getElement()).hasClass("with-code")) {
              toolbar.push("code");
            }

            toolbar.push("fullscreen");

            if (censurableEditorsSetting.indexOf(editor.id) >= 0) {
              editor.addButton("drasticcontent", {
                icon: "eye-slash",
                tooltip: "Oznacz zdjęcie jako treść wrażliwa",
                onclick(_editor) {
                  const imgObject = tinyMCE.activeEditor.selection.getNode();

                  if (imgObject.hasAttribute("data-censored")) {
                    imgObject.removeAttribute("data-censored");
                    $(imgObject).parent().removeClass("censored-img");
                    this.active(false);
                  } else {
                    imgObject.setAttribute("data-censored", true);
                    $(imgObject).parent().addClass("censored-img");
                    this.active(true);
                  }
                },

                onpostrender() {
                  editor.on("NodeChange", (event) => {
                    this.disabled(true);
                    this.parent().hide();

                    if (event.element.nodeName.toLowerCase() === "img") {
                      this.disabled(false);
                      this.parent().show();
                      this.active(event.element.hasAttribute("data-censored"));
                    }
                  });
                },
              });
            }

            toolbar.push("drasticcontent");

            if ($(editor.getElement()).attr("id") === "newsletter_campaign_body") {
              toolbar.push("numlist bullist image");

              editor.settings.content_css = tinymceNewsletterCssUrl;

              const progressbarUrl = $(editor.getElement()).attr("data-progressbar-cause-url");

              if (progressbarUrl) {
                editor.addButton("addprogressbar", {
                  icon: "hr",
                  text: "Pasek",
                  tooltip: "Wstaw grafikę postępu",
                  onclick(_editor) {
                    // eslint-disable-next-line max-len
                    const content = `<p><img src="${progressbarUrl}" data-progressbar width="370" alt="Pasek postępu zbiórki"></p>`;

                    tinymce.activeEditor.insertContent(content);
                  },
                });

                toolbar.push("addprogressbar");
              }

              const causeEndsAt = $(editor.getElement()).attr("data-cause-ends-at");
              const causeFinishedAt = $(editor.getElement()).attr("data-cause-finished-at");

              if (causeEndsAt || causeFinishedAt) {
                editor.addButton("addcountdown", {
                  icon: "hr",
                  text: "Timer",
                  tooltip: "Wstaw grafikę odliczającą czas do końca zbiórki",
                  onclick(_editor) {
                    const time = causeFinishedAt || causeEndsAt;

                    // eslint-disable-next-line max-len
                    const content = `<p><img src="${newsletterTimerUrlSetting}?end_date_time=${time}" alt="Czas do zakończenia zbiórki" width="430"></p>`;

                    tinymce.activeEditor.insertContent(content);
                  },
                });
                toolbar.push("addcountdown");
              }
            }

            editor.settings.toolbar = toolbar.join(" | ");
          },
        });

        Siepomaga.cleanOnLoad(() => {
          tinymce.remove();
        });
      });
    }
  }
};
