Siepomaga.common_payu = {
  show() {
    Siepomaga.timeout(3000, () => {
      Siepomaga.common_payu.checkStatus();
    });

    Siepomaga.timeout(20000, () => {
      Turbolinks.visit("/platnosc/success");
    });
  },

  new() {
    Siepomaga.onlyMobile({
      create() {
        $(".iframe-container iframe").attr("height", "540px");
      },
      destroy() {
        $(".iframe-container iframe").attr("height", "450px");
      },
    });
  },

  create() {
    Siepomaga.common_payu.new();
  },

  checkStatus() {
    $.getJSON("", (response) => {
      switch (response.state) {
        case "confirmed":
          $(".payu-card").removeClass("sp-loader");
          $(".payu-card").addClass("success");
          Siepomaga.timeout(1000, () => {
            Turbolinks.visit(response.url);
          });
          break;
        case "created":
          Siepomaga.timeout(2000, () => {
            Siepomaga.common_payu.checkStatus();
          });
          break;
        default:
          $(".payu-card").removeClass("sp-loader");
          $(".payu-card").addClass("error");
          Siepomaga.timeout(1000, () => {
            Turbolinks.visit(response.url);
          });
          break;
      }
    });
  },

  iframe() {
    new Siepomaga.CreditCard();
  },
};
