Siepomaga.CountUp = class CountUp {
  constructor(element, newValue, suffix, callback) {
    const $el = $(element);

    if (($el.length > 0) && (newValue !== $el.data("value"))) {
      const precision = parseFloat(parseInt(newValue, 10)) === parseFloat(newValue) ? 0 : 2;
      const countUp = new window.CountUp($el.get(0), $el.data("value"), newValue, precision, 3, {
        useEasing: true,
        useGrouping: true,
        separator: " ",
        decimal: ",",
        suffix,
      });
      countUp.start(callback);
      $el.data("value", newValue);
    }
  }
};
