const { sprocketsManifestPath, railsEnv } = require("railsVariables");

const manifests = {
  webpack: null,
  sprockets: null,
};

const loadManifest = async (type, manifestPath) => {
  if (!manifests[type]) {
    const response = await fetch(`/assets/${manifestPath}`);

    if (!response.ok) {
      throw new Error(`Manifest file not found: ${manifestPath}`);
    }

    manifests[type] = await response.json();
  }
};

const getPathFromSprockets = async (assetName) => {
  if (railsEnv === "development" || railsEnv === "test") {
    const response = await fetch(`/rails/assets?asset_name=${assetName}&now=${Date.now()}`);
    if (!response.ok) {
      throw new Error(`URL to asset not found: ${assetName}`);
    }

    const data = await response.json();
    return data.path;
  }

  await loadManifest("sprockets", sprocketsManifestPath);

  return manifests.sprockets.assets[assetName];
};

// This function is used to get the path to an asset from the webpack or sprockets manifest file.
// Remember that the webpack file needs to be generated via MergeIntoSingleFilePlugin.
const assetUrls = async (...assetNames) => {
  const webpackManifestPath = await getPathFromSprockets("webpack-assets-manifest.json");

  await loadManifest("webpack", webpackManifestPath);

  const assetPaths = await Promise.all(assetNames.map(async (assetName) => {
    let assetPath = manifests.webpack[assetName];

    if (!assetPath) {
      assetPath = await getPathFromSprockets(assetName);
    }

    if (!assetPath) {
      throw new Error(`Asset not found: ${assetName}`);
    }

    return `/assets/${assetPath}`;
  }));

  return assetPaths;
};

module.exports = { assetUrls };
