Siepomaga.common_foundations = {
  show() {
    $("p:has(iframe[data-vertical=true])").addClass("has-vertical-video");
    $("p:has(iframe[data-height-ratio])").each((i, el) => {
      $(el).addClass("has-height-ratio");
      const ratio = $(el).find("iframe").data("height-ratio");
      if ($(el).hasClass("has-vertical-video")) {
        $(el).css("padding-bottom", `${270 * ratio}px`);
      } else {
        $(el).css("padding-bottom", `${ratio * 100}%`);
      }
    });

    $("iframe[data-src]").visibility({
      onTopVisible() {
        $(this).attr("src", $(this).data("src"));
      },
    });

    $("a[data-tab='wplaty']").offon("click", (_event) => {
      $("#foundation-payment-loader").visibility("refresh");
    });

    if ($(".tab.active[data-tab!='wplaty']").length) {
      $(".tabs .ui.menu").removeClass("hidden");
    }

    $(".tabs#payments").visibility({
      onTopVisible() {
        const url = $("#foundation-payment-loader").data("url");
        Siepomaga.getScript(url, () => {
          $(".tabs .ui.menu").removeClass("hidden");
        });
      },
    });

    Siepomaga.causes.refreshPayments();
    let constantHelpSlideshowDesktop = null;
    const virtualAdoptionsSlideshowDesktop = null;

    Siepomaga.onlyDesktop({
      create() {
        $("#right-column .ui.sticky").sticky({
          offset: 20,
          context: "#left-column",
          observeChanges: "#left-column",
        });

        $("#left-column img").on("load", () => {
          $("#right-column .ui.sticky").sticky("refresh");
        });

        const virtualAdoptionsSlideshowElement = $("#foundations-show #right-column .virtual-adoptions");

        if (virtualAdoptionsSlideshowElement.length !== 0) {
          new Siepomaga.AnimalCarousel(virtualAdoptionsSlideshowElement);
        }

        Siepomaga.timeout(1000, () => {
          // eslint-disable-next-line max-len
          const slideshowElement = $("#foundations-show #right-column .foundation-constant-help-widget .avatar-carousel");

          if (slideshowElement.length === 0) { return; }

          constantHelpSlideshowDesktop = new Siepomaga.SlickSlideshow(slideshowElement);
          const slidingFields = ["name", "amount"];
          constantHelpSlideshowDesktop.initialize(slidingFields);
        });
      },

      destroy() {
        $("#right-column .ui.sticky").sticky("destroy");
        $("#left-column img").off("load");

        if (constantHelpSlideshowDesktop) {
          constantHelpSlideshowDesktop.destructor();
        }

        if (virtualAdoptionsSlideshowDesktop) {
          virtualAdoptionsSlideshowDesktop.destructor();
        }
      },
    });

    const handleScroll = () => {
      if (!$("#navigation .mobile-menu:visible").length || !$("#left-column .big.sp-button").length) { return; }

      // eslint-disable-next-line max-len
      const bigSpButtonYPosition = $("#left-column .big.sp-button").offset().top + $("#left-column .big.sp-button").outerHeight() - $("#navigation").outerHeight();

      if ((window.scrollY + window.innerHeight) >= $(document).height()) {
        $(".mobile-donation-buttons").removeClass("active");
      } else if (window.scrollY > bigSpButtonYPosition) {
        $(".mobile-donation-buttons").addClass("active");
      } else {
        $(".mobile-donation-buttons").removeClass("active");
      }
    };

    handleScroll();
    let virtualAdoptionsSlideshowMobile = null;
    let constantHelpSlideshowMobile = null;

    Siepomaga.onlyTablet({
      create() {
        const virtualAdoptionsSlideshowElement = $("#foundations-show .mobile-navigation .virtual-adoptions");

        if ($(virtualAdoptionsSlideshowElement).length !== 0) {
          virtualAdoptionsSlideshowMobile = new Siepomaga.AnimalCarousel(virtualAdoptionsSlideshowElement);
        }

        Siepomaga.timeout(1000, () => {
          // eslint-disable-next-line max-len
          const slideshowElement = $("#foundations-show .mobile-navigation .foundation-constant-help-widget .avatar-carousel");

          if ($(slideshowElement).length === 0) { return; }

          constantHelpSlideshowMobile = new Siepomaga.SlickSlideshow(slideshowElement);

          const slidingFields = ["name", "amount"];
          constantHelpSlideshowMobile.initialize(slidingFields);
        });

        $(window).on("scroll", handleScroll);
      },

      destroy() {
        if (constantHelpSlideshowMobile) {
          constantHelpSlideshowMobile.destructor();
        }
        if (virtualAdoptionsSlideshowMobile) {
          virtualAdoptionsSlideshowMobile.destructor();
        }

        constantHelpSlideshowMobile = null;
        virtualAdoptionsSlideshowMobile = null;

        $(window).off("scroll", handleScroll);
        $(".mobile-donation-buttons").removeClass("active");
      },
    });
  },

  new() {
    new Siepomaga.SecurePassword();
  },

  create() {
    Siepomaga.common_foundations.new();
  },
};
