Siepomaga.Cookies = class Cookies {
  constructor() {
    if ($.cookie("cc-cookies")) { $.removeCookie("cc-cookies"); }
    if ($.cookie("sp-cookies-v1") !== "off") {
      $("#cookies .more").offon("click", (event) => {
        event.preventDefault();
        $("#cookies").addClass("open");
        $(".more-info").slideDown(() => {
          $(".ui.sticky:visible").sticky("refresh");
        });
      });

      $("#cookies .sp-button").offon("click", (event) => {
        event.preventDefault();
        $.cookie("sp-cookies-v1", "off", { path: "/", expires: 30 * 12 }); // one year
        $("#cookies").slideUp(() => {
          $(".ui.sticky:visible").sticky("refresh");
        });
      });
    }

    Siepomaga.onlyTablet({
      create() {
        $("#navigation").sticky({ context: "#page" });
      },
      destroy() {
        $("#navigation").sticky("destroy");
      },
    });
  }
};
