Siepomaga.FormErrorsHandler = class FormErrorsHandler {
  constructor(context = null) {
    $("form input, form select, form .dropzone, form textarea, form field_with_error input", context)
      .offon("focus click select2:opening keypress", (event) => {
        const { target, currentTarget, type } = event;

        if ((type === "focus") && ($.inArray(target.id, ["payment_amount", "constant_help_amount"]) !== -1)) {
          return;
        }

        if ($(currentTarget).parent().hasClass("field_with_errors")) {
          const errorDiv = $(currentTarget).parents().next(".error");
          errorDiv.slideUp(() => errorDiv.remove());
        } else {
          const wrapper = $(currentTarget).closest(".wrapper");

          if (wrapper.hasClass("field_with_errors")) {
            const errorDiv = wrapper.find(".error");
            errorDiv.slideUp(() => errorDiv.remove());
            wrapper.removeClass("field_with_errors");
          }
        }
      });
  }
};
