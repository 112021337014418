Siepomaga.Socialmedia = class Socialmedia {
  constructor() {
    this.loadFacebook();
  }

  loadFacebook() {
    window.fbAsyncInit = () => {
      FB.init({
        appId: $("meta[property='fb:app_id']").attr("content"),
        status: true,
        cookie: true,
        xfbml: true,
        version: "v2.8",
      });
    };

    // eslint-disable-next-line max-len
    $.getScript(`https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v2.8&appId=${$("meta[property='fb:app_id']").attr("content")}`);

    $(document).on("turbolinks:request-start", (event) => {
      if ($("#fb-root").length) {
        event.currentTarget.fbRoot = $("#fb-root").detach();
      }
    });

    $(document).on("turbolinks:load", (event) => {
      const { fbRoot } = event.currentTarget;

      if (fbRoot) {
        if ($("#fb-root").length) {
          $("#fb-root").replaceWith(fbRoot);
        } else {
          $("body").append(fbRoot);
        }
      }
    });

    $(document).on("turbolinks:load", () => {
      if (typeof FB !== "undefined" && FB !== null) {
        FB.XFBML.parse();
      }
    });
  }
};
