// Avatar Carousel component.
// Replaces HTML element content with content from matching slide data attribute. It can also replace HTML link hrefs.
// To connect HTML element with dynamic slide information,
// HTML element class name must be the same as related slide data attribute name.
// Ex. when you want to change name when slide changes,
// in view put first slide name under html element with '.name' class,
// and put name in every slide in 'name' data attr in slide data.
// Then html element wih name class will have content changed every slide change.
// The same with element href replacing.
// Note: javascript uses HTML data attribute names as camelCase in DOM element dataset instead of kebab-case,
// so HTML class should have also camelCase name.

// Additional functionality: when slide is changed manually by button (arrow), then next slide is played longer.
// After it changes (by autoplay), autoplay speed returns to normal.

Siepomaga.SlickSlideshow = class SlickSlideshow {
  destructor() {
    if (this.slideshow == null) { return; }

    this.slideshow.off("beforeChange");
    this.slideshow.off("afterChange");
    this.slideshow.slick("unslick");
  }

  // el - carousel HTML element where slider will be placed
  constructor(el, options) {
    let opts = options;
    if (opts == null) { opts = {}; }

    this.selectedSlideAutoplayTime = 4500;
    this.defaultAutoplayTime = 3000;
    this.animationSpeed = 1000;

    this.el = el;
    this.currentSlideChangedByButtonClick = false;

    this.slideshow = el.find(".avatars").slick(this.sliderOptions(opts));
  }

  // classNames - HTML elements class names to use for sliding HTML content (required).
  // hrefs - HTML elements class name to use for sliding element href content (optional).
  // Pass class names without leading dot.
  initialize(classNames, newHrefs) {
    let hrefs = newHrefs;
    if (hrefs == null) { hrefs = []; }

    const { slick } = this.slideshow.get(0);

    // Hide labels for first slide when it is placeholder slide
    const { slides } = slick;
    if (slides) { this.hideDataForPlaceholderSlide(this.el, slides[0], classNames); }

    // Every slide has change slide button, hide all buttons except currrent slide one.
    $(".slick-slide").not(".slick-current").find(".slick-arrow").hide();
    $(".slick-slide").not(".slick-current").find(".zoom-image").hide();
    $(".slick-slide.slick-current").find(".zoom-image").show();

    const changeSlideButton = this.el.find(".slick-arrow");
    changeSlideButton.offon("click", (_event) => {
      this.currentSlideChangedByButtonClick = true;
      slick.slickPlay(); // There was bug on mobile, when slider stopped playing after slide change, this fixes it.
      slick.slickSetOption("autoplaySpeed", this.selectedSlideAutoplayTime);
    });

    this.slideshow.on("afterChange", (_event, eventSlick, currentSlide) => {
      const currentSlideEl = eventSlick.$slides[currentSlide];
      if (!this.isPlaceholderSlide(currentSlideEl)) { $(currentSlideEl).find(".slick-arrow").show(); }
      $(currentSlideEl).find(".zoom-image").show();
    });

    this.slideshow.on("beforeChange", (_event, eventSlick, currentSlide, nextSlide) => {
      if (currentSlide === nextSlide) { return; }

      this.el.find(".slick-arrow").hide();
      this.el.find(".zoom-image").hide();

      // Skip placeholder slide.
      // Slide can be changed only when sliding animation is done, so wait for it.
      // Wait additional small amount of time (25ms) to make sure animation ends,
      // because of async nature of js timeouts sometimes were executed before animation ended
      // and nothing happened then.
      if (slick.$slides !== null && this.isPlaceholderSlide(slick.$slides[nextSlide])) {
        setTimeout(
          () => {
          // placeholder slide is always first, so if current slide is 0,
          // that means that somebody used prev arrow to change slide, and it is placeholder slide,
          // so skip it with proper direction
            if (currentSlide === 0) {
              eventSlick.slickPrev();
            } else {
              eventSlick.slickNext();
            }
          },
          this.animationSpeed + 25,
        );
        return;
      }

      if (this.currentSlideChangedByButtonClick) {
        this.resetAutoplaySpeed(eventSlick);
        this.currentSlideChangedByButtonClick = false;
      }

      // Replace element content every slide with value from slide data attribute.
      this.changeSlideData(classNames, hrefs, eventSlick, nextSlide);
    });
  }

  hideDataForPlaceholderSlide(_el, firstSlide, classNames) {
    classNames.forEach((className) => {
      const element = this.el.find(`.${className}`);

      const firstSlideElementContent = firstSlide.dataset[className];

      if (firstSlideElementContent === "") {
        element.css("visibility", "hidden");
      }
    });
  }

  resetAutoplaySpeed(slick) {
    const currentAutoplaySpeed = slick.slickGetOption("autoplaySpeed");

    if (currentAutoplaySpeed === this.selectedSlideAutoplayTime) {
      slick.slickSetOption("autoplaySpeed", this.defaultAutoplayTime);
    }
  }

  changeSlideData(classNames, hrefs, slick, nextSlide) {
    let element;
    hrefs.forEach((href) => {
      element = this.el.find(`.${href}`);
      const linkHref = slick.$slides[nextSlide].dataset[href];

      element.attr("href", linkHref);
    });

    classNames.forEach((className) => {
      element = this.el.find(`.${className}`);

      const contentFromSlide = slick.$slides[nextSlide].dataset[className];

      if (contentFromSlide === "") {
        element.css("visibility", "hidden");
      } else {
        element.html(contentFromSlide);
        element.css("visibility", "initial");
      }
    });
  }

  // Prepare and merge default slider options with custom options
  sliderOptions(customOpts) {
    const defaultOpts = {
      arrows: false,
      accessibility: false,
      dots: false,
      centerMode: true,
      variableWidth: true,
      infinite: true,
      autoplay: true,
      focusOnSelect: false,
      autoplaySpeed: this.defaultAutoplayTime,
      useTransform: false,
      speed: this.animationSpeed,
      swipe: false,
      draggable: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      touchMove: false,
      slidesToShow: 1,
    };

    if (customOpts.pauseOnHover) { defaultOpts.pauseOnHover = customOpts.pauseOnHover; }

    if (customOpts.arrows) {
      defaultOpts.arrows = customOpts.arrows;
      if (customOpts.nextArrow) { defaultOpts.nextArrow = customOpts.nextArrow; }
      if (customOpts.prevArrow) { defaultOpts.prevArrow = customOpts.prevArrow; }
      if (customOpts.appendArrows) { defaultOpts.appendArrows = customOpts.appendArrows; }
    }

    return defaultOpts;
  }

  isPlaceholderSlide(slideEl) {
    return (slideEl.dataset.isPlaceholder != null);
  }
};
