import "./cropper";
import "./select2";
import "./tinymce";
import "./dropzone";
import "./tabs";
import "./form_errors_handler";

Siepomaga.Form = class Form {
  constructor() {
    const formSelector = (type) => `form[method=${type}]:not([data-remote]):not(.remote-modal):not(.no-turbolinks)`;
    $(document).on("submit", formSelector("get"), (event) => {
      const { currentTarget } = event;

      let url = `${currentTarget.action.split("#")[0].split("?")[0]}?${$(currentTarget).serialize()}`;

      const anchor = currentTarget.action.split("#")[1];
      if (anchor) { url += `#${anchor}`; }

      Turbolinks.visit(url);
      return false;
    });

    $(document).on("change", "select.select-redirect", (event) => {
      const { currentTarget } = event;

      $(currentTarget).attr("disabled", "disabled");
      const query = $("#q").val();
      Turbolinks.visit(($(currentTarget).val() || $(currentTarget).data("default-url")) + (query ? `?q=${query}` : ""));
    });

    $(document).on("submit", formSelector("post"), (event) => {
      const { currentTarget } = event;

      event.preventDefault();

      $(currentTarget).ajaxSubmit({
        success: (data, _textStatus, jqXHR) => {
          Siepomaga.cleanOnLoad(() => {
            Rails.enableElement(currentTarget);
          });

          $("[data-crop][data-crop-init]").each((i, el) => {
            new Siepomaga.Cropper(el, $(el).data("crop"));
          });

          if (!jqXHR.getResponseHeader("Content-Type").match("text/javascript")) {
            $(document).trigger("turbolinks:request-end");
            Siepomaga.stopProgress();
            Siepomaga.replaceBody(data);

            if ($(".field_with_errors").length > 0) {
              const offset = $("body").data("controller").match(/^panel_/) ? 200 : 15;
              const scroll = $(".field_with_errors").first().offset().top - offset;
              $("html, body").animate({ scrollTop: scroll }, 1000);
            } else {
              $("html, body").animate({ scrollTop: 0 }, 1000);
            }
          }
        },

        error: (jqXHR, _textStatus) => {
          if (jqXHR.status === 0) {
            Rails.enableElement(currentTarget);

            // eslint-disable-next-line no-alert
            alert("Brak połączenia z internetem.");
          } else {
            Siepomaga.render(jqXHR.responseText);
          }
        },

        always: () => {
          this.enableEmptyFileInputs(currentTarget);
        },

        beforeSerialize: (form, options) => {
          this.disableEmptyFileInputs(form, options);
        },
      });
    });

    $(document).on("submit", "form.remote-modal", (event) => {
      const { currentTarget } = event;
      event.preventDefault();

      $(currentTarget).ajaxSubmit({
        success: (body, _textStatus, data) => {
          Siepomaga.cleanOnLoad(() => {
            Rails.enableElement(currentTarget);
          });

          if (!data.getResponseHeader("Content-Type").match("text/javascript")) {
            if ($(".ui.modal.active textarea.tinymce").length > 0) {
              tinymce.remove(".ui.modal.active textarea.tinymce");
            }

            if ($(".ui.modal.active").length) {
              $(".ui.modal.active").html($(body).html());

              new Siepomaga.Select2();
              new Siepomaga.Tinymce();
              if ($(".dropzone").length && !$(".dz-clickable").length) {
                new Siepomaga.Dropzone(".ui.modal.active");
              }
              new Siepomaga.Tabs($(".ui.modal.active"));
              new Siepomaga.FormErrorsHandler($(".ui.modal.active"));

              $(".ui.modal.active [data-content]").popup({
                inline: true,
                delay: {
                  show: 0,
                  hide: 0,
                },
                hideOnScroll: true,
              });
            } else {
              $("#remote-modal").html(body);

              const remoteModal = $("#remote-modal .ui.modal");
              remoteModal.find("img").offon("load", () => {
                remoteModal.modal("refresh");
              });

              if ($(".dropzone").length && !$(".dz-clickable").length) {
                new Siepomaga.Dropzone("#remote-modal .ui.modal");
              }

              remoteModal.modal({
                onShow() {
                  new Siepomaga.Tinymce();
                  remoteModal.modal("refresh");
                },
                onVisible() {
                  new Siepomaga.Select2();
                  remoteModal.find("[data-content]").popup({
                    inline: true,
                    delay: {
                      show: 0,
                      hide: 0,
                    },
                    hideOnScroll: true,
                  });

                  remoteModal.modal("refresh");
                },

                onHidden() {
                  if ($(".ui.modal textarea.tinymce").length > 0) {
                    tinymce.remove(".ui.modal textarea.tinymce");
                  }
                },
              });

              remoteModal.modal("show");
            }

            $(".ui.modal.active").modal("refresh");

            $(".ui.modal.active [data-crop][data-crop-init]").each((i, el) => {
              new Siepomaga.Cropper(el, $(el).data("crop"));
            });

            const modalData = $(`<div>${body}</div>`).find(".ui.modal").data();
            if (modalData.controller && modalData.action) {
              Siepomaga.runAction(modalData.controller, modalData.action);
            }
          }
        },

        error: (jqXHR, _textStatus) => {
          if (jqXHR.status === 0) {
            Rails.enableElement(currentTarget);

            // eslint-disable-next-line no-alert
            alert("Brak połączenia z internetem.");
          } else {
            Siepomaga.render(jqXHR.responseText);
          }
        },

        always: () => {
          this.enableEmptyFileInputs(currentTarget);
        },

        beforeSerialize: (form, options) => {
          this.disableEmptyFileInputs(form, options);
        },
      });
    });

    $(document).on("change", "form.inline select", (event) => {
      $(event.currentTarget.form).submit();
    });

    $(document).on("change", "form.inline input[type=checkbox]", (event) => {
      $(event.currentTarget.form).submit();
    });

    $(document).on("click", "form .attachment i.remove.icon", (event) => {
      const attachement = $(event.currentTarget).parent();

      attachement.fadeOut(() => {
        if (attachement.data("new-record")) {
          attachement.remove();
        } else {
          attachement.find("input[type=hidden]").val("1");
        }
      });
    });

    $(document).on("change", "form .file-input input[type=file]", (event) => {
      $(event.currentTarget).prev("i").show();
    });

    $(document).on("click", "form .file-input i.remove.icon", (event) => {
      $(event.currentTarget).next("input").val("");
      $(event.currentTarget).hide();
    });

    $(document).on("change", "input[data-inline-change]", (event) => {
      const { currentTarget } = event;

      $.ajax({
        method: "PUT",
        dataType: "text",
        url: $(currentTarget).data("inline-change"),
        data: {
          what: $(currentTarget).attr("name"),
        },
        success() {},
        error: () => {
          currentTarget.checked = !currentTarget.checked;

          // eslint-disable-next-line no-alert
          alert("Brak połączenia z internetem.");
        },
      });
    });
  }

  disableEmptyFileInputs(form, _options) {
    $(form).find("input[type=file]").each((i, el) => {
      if (!el.files.length && !el.disabled) {
        $(el).data("emptyFileInput", true).attr("disabled", "disabled");
      }
    });
  }

  enableEmptyFileInputs(form) {
    $(form).find("input[type=file]").each((i, el) => {
      if ($(el).data("emptyFileInput") && el.disabled) {
        $(el).removeData("emptyFileInput").removeAttr("disabled");
      }
    });
  }
};
