Siepomaga.common_cart_items = {
  new() {
    let cachedValue = "";

    const changeAmount = () => {
      // eslint-disable-next-line max-len
      let amount = $("#payment_hide_amount").is(":checked") ? "X" : Siepomaga.formatAmount($("#payment_amount").val() || "0");

      $("#new_payment .sp-payment .sp-payment-amount strong").html(`${amount} zł`);

      $("#new_payment .currencies div").each((i, el) => {
        let convertedAmount;
        amount = $("#payment_amount").val() || "0";
        const parsedAmount = amount.replace(",", ".");
        if (parsedAmount > 0) {
          convertedAmount = Siepomaga.formatAmount(parsedAmount / $(el).data("rate"));
        } else {
          convertedAmount = 0;
        }

        $("span", el).html(convertedAmount);
      });
    };

    const validateAmount = () => {
      const value = $("#payment_amount").val();
      if (!value || value.match(/^\d{1,7}([,.]\d?\d?)?$/)) {
        cachedValue = value;
        changeAmount();
      } else {
        $("#payment_amount").val(cachedValue);
      }
    };

    $("#new_payment").offon("keypress", (event) => {
      if (event.which === 13) { event.preventDefault(); }
    });

    $("#payment_hide_amount").offon("change", () => {
      if ($("#payment_hide_amount:checked").length) {
        $("#payment_anonymous").prop("checked", false);
        $("#payment_anonymous").change();
      }
      changeAmount();
    });

    $("#payment_amount").offon("keyup", () => {
      validateAmount();
    });

    $("#payment_amount").offon("paste", () => {
      Siepomaga.timeout(100, () => {
        validateAmount();
      });
    });

    const updatePayerName = () => {
      let avatar;
      let userAvatar;
      let name;
      const userSex = $("#new_payment .sp-payment").data("user-sex");
      const woman = (userSex === "kobieta") || $("#payment_sex_k:checked").length;
      const anonymous = $("#payment_anonymous:checked").length;

      const userAvatarData = $("#new_payment .sp-payment").data("user-avatar");
      const womanAvatarData = $("#new_payment .sp-payment").data("woman");
      const manAvatarData = $("#new_payment .sp-payment").data("man");
      if (userAvatarData) {
        userAvatar = userAvatarData;
      } else if (woman) {
        userAvatar = womanAvatarData;
      } else {
        userAvatar = manAvatarData;
      }
      avatar = userAvatar;

      const publicName = $("#new_payment .sp-payment").data("user-public-name");

      if (woman) {
        name = $("#new_payment .sp-payment").data("user-anonymous-k");
        avatar = $("#new_payment .sp-payment").data("woman");
      } else {
        name = $("#new_payment .sp-payment").data("user-anonymous-m");
        avatar = $("#new_payment .sp-payment").data("man");
      }

      if (anonymous) {
        $("#payment_signature").val(name);
      } else if (publicName) {
        name = publicName;
        avatar = userAvatar;
      } else if (Siepomaga.sanitizeUserInput($("#payment_signature").val())) {
        name = Siepomaga.sanitizeUserInput($("#payment_signature").val());
        avatar = userAvatar;
      }

      $("#new_payment .sp-payment .sp-payment-name").text(name);
      $("#new_payment .sp-payment img").attr("src", avatar);
    };

    let nameCache = Siepomaga.sanitizeUserInput($("#payment_signature").val());

    $("input[name=\"payment[sex]\"]").offon("change", updatePayerName);
    $("#payment_signature").offon("keyup", () => {
      updatePayerName();
      nameCache = Siepomaga.sanitizeUserInput($("#payment_signature").val());
    });

    const updateAnonymous = () => {
      if ($("#payment_anonymous:checked").length) {
        nameCache = Siepomaga.sanitizeUserInput($("#payment_signature").val());
      }

      if ($("#payment_anonymous:checked").length) {
        $("#payment_hide_amount").prop("checked", false);
        $("#payment_hide_amount").change();
        if ($(".signature").hasClass("field_with_errors")) {
          const errorDiv = $(".signature").next(".error");
          errorDiv.slideUp(() => errorDiv.remove());
        }
      }

      if ($("#payment_anonymous:checked").length) {
        const userSex = $("#new_payment .sp-payment").data("user-sex");
        const woman = (userSex === "kobieta") || $("#payment_sex_k:checked").length;
        if (woman) {
          $("#payment_signature").val("Anonimowa Pomagaczka");
        } else {
          $("#payment_signature").val("Anonimowy Pomagacz");
        }
        $("#payment_signature").attr("disabled", "disabled");
      } else {
        $("#payment_signature").val(nameCache);
        $("#payment_signature").attr("disabled", false);
      }

      updatePayerName();
    };

    updateAnonymous();

    $("#payment_anonymous").offon("change", updateAnonymous);

    $("#payment_comment_text").offon("keyup", (event) => {
      const comment = Siepomaga.sanitizeUserInput($(event.currentTarget).val());
      $("#new_payment .sp-payment .sp-payment-comment").text(comment);
    });

    $("#pay_now").offon("click", (event) => {
      $(event.currentTarget).append($("<input type=\"hidden\" name=\"pay_now\"/>"));
    });

    $("#new_payment button").offon("click", (event) => {
      $("#new_payment button").not($(event.currentTarget)).removeAttr("data-disable-with").attr("disabled", true);
    });
  },

  create() {
    Siepomaga.common_cart_items.new();
  },

  edit() {
    let cachedValue = $("#payment_amount").val() || 0;

    const validateAmount = () => {
      const value = $("#payment_amount").val();
      if (!value || value.match(/^\d{1,7}([,.]\d?\d?)?$/)) {
        cachedValue = value;
        $("#new_payment .currencies div").each((i, el) => {
          let convertedAmount;
          const amount = $("#payment_amount").val() || "0";
          const parsedAmount = amount.replace(",", ".");
          if (parsedAmount > 0) {
            convertedAmount = Siepomaga.formatAmount(parsedAmount / $(el).data("rate"));
          } else {
            convertedAmount = 0;
          }

          $("span", el).html(convertedAmount);
        });
      } else {
        $("#payment_amount").val(cachedValue);
      }
    };

    $("#payment_amount").offon("keyup", () => {
      validateAmount();
    });

    $("#payment_amount").offon("paste", () => {
      Siepomaga.timeout(100, () => {
        validateAmount();
      });
    });

    let nameCache = Siepomaga.sanitizeUserInput($("#payment_signature").val());

    $("#payment_signature").offon("keyup", () => {
      nameCache = Siepomaga.sanitizeUserInput($("#payment_signature").val());
    });

    const updateAnonymous = () => {
      if ($("#payment_anonymous:checked").length) {
        nameCache = Siepomaga.sanitizeUserInput($("#payment_signature").val());
      }

      if ($("#payment_anonymous:checked").length) {
        $("#payment_hide_amount").prop("checked", false);
      }

      if ($("#payment_anonymous:checked").length) {
        // eslint-disable-next-line max-len
        const woman = ($("#new_payment .sp-payment").data("user-sex") === "kobieta") || $("#payment_sex_k:checked").length || $("#payments-new[data-user-sex=\"kobieta\"]").length;

        if (woman) {
          $("#payment_signature").val("Anonimowa Pomagaczka");
        } else {
          $("#payment_signature").val("Anonimowy Pomagacz");
        }
        $("#payment_signature").attr("disabled", "disabled");

        if ($(".signature").hasClass("field_with_errors")) {
          const errorDiv = $(".signature").next(".error");
          errorDiv.slideUp(() => errorDiv.remove());
        }
      } else {
        $("#payment_signature").val(nameCache);
        $("#payment_signature").attr("disabled", false);
      }
    };

    updateAnonymous();

    $("#payment_anonymous").offon("change", updateAnonymous);

    $("#payment_hide_amount").offon("change", () => {
      if ($("#payment_hide_amount:checked").length) {
        $("#payment_anonymous").prop("checked", false);
        $("#payment_anonymous").change();
      }
    });

    $("input[name=\"payment[sex]\"]").offon("change", () => {
      const woman = $("#payment_sex_k:checked").length;
      const anonymous = $("#payment_anonymous:checked").length;

      if (Siepomaga.sanitizeUserInput($("#payment_signature:enabled").val())) { return; }

      if (anonymous) {
        if (woman) {
          $("#payment_signature").val($("#new_payment .sp-payment").data("user-anonymous-k"));
        } else {
          $("#payment_signature").val($("#new_payment .sp-payment").data("user-anonymous-m"));
        }
      } else {
        $("#payment_signature").val("");
      }
    });
  },

  update() {
    this.edit();

    // Form errors hiding after click
    $("form input").offon("focus click", (event) => {
      if ($(event.target).parent().hasClass("field_with_errors")) {
        const errorDiv = $(event.target).parents().next(".error");

        errorDiv.slideUp(() => errorDiv.remove());
      }
    });
  },
};
